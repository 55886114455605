import React, { FC } from 'react';
import { graphql } from 'gatsby';

import ExternalLink from 'common/ExternalLink';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { ILinksSectionProps } from './model';

import './LinksSection.scss';

const LinksSection: FC<ILinksSectionProps> = ({ title, links }) => (
  <div className="links-section">
    <div className="links-section__container">
      <DangerouslySetInnerHtml className="links-section__title" html={title} />
      <ul className="links-section__list">
        {links.map(
          ({
            properties: {
              link: [{ url, name }],
              ariaLabel,
            },
          }) => (
            <li key={url} className="links-section__list-item">
              <ExternalLink
                className="links-section__list-item-link"
                link={url}
                ariaLabel={ariaLabel}
              >
                {name}
              </ExternalLink>
            </li>
          )
        )}
      </ul>
    </div>
  </div>
);

export const query = graphql`
  fragment FragmentLinksSection on TLinksSection {
    properties {
      title
      links {
        properties {
          link {
            name
            url
          }
          ariaLabel
        }
      }
    }
    structure
  }
`;

export default LinksSection;
