import React, { FC } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { ISaveWaterProductsProps } from './model';

import './SaveWaterProducts.scss';

const SaveWaterProducts: FC<ISaveWaterProductsProps> = ({
  title,
  logo,
  productImages,
  decorativeElement,
}) => (
  <div className="save-water-products">
    <div className="save-water-products__container">
      <DangerouslySetInnerHtml className="save-water-products__title" html={title} />
      <GatsbyImage
        className="save-water-products__logo"
        image={logo[0].properties.image}
        alt={logo[0].properties.imageAlt}
      />
      {productImages?.length ? (
        <ul className="save-water-products__products">
          {productImages.map((product) => (
            <li
              key={product.properties.image.fallbackUrl}
              className="save-water-products__products-product"
            >
              <GatsbyImage
                image={product.properties.image}
                alt={product.properties.imageAlt}
                objectFit="contain"
              />
            </li>
          ))}
        </ul>
      ) : null}
    </div>
    <GatsbyImage
      className="save-water-products__decorative-element"
      image={decorativeElement[0].properties.image}
      alt={decorativeElement[0].properties.imageAlt}
      isLazyLoading={false}
    />
  </div>
);

export const query = graphql`
  fragment FragmentSaveWaterProducts on TSaveWaterProducts {
    properties {
      title
      logo {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 275, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      productImages {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 380, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      decorativeElement {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 254) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;

export default SaveWaterProducts;
