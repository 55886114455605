import React, { forwardRef } from 'react';

import GatsbyImage from 'common/GatsbyImage';

import { IEBookPage } from './models';
import './EBookPage.scss';

const EBookPage = forwardRef<null | HTMLDivElement, IEBookPage>(({ image }, ref) => {
  const {
    properties: { image: imagePage, imageAlt },
  } = image[0];

  return (
    <div className="ebook-page" ref={ref}>
      <GatsbyImage
        objectFit="contain"
        image={imagePage}
        alt={imageAlt}
        className="ebook-page__image"
      />
    </div>
  );
});

export default EBookPage;
