import React, { FC } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';
import Video from 'common/Video';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { IWaterWasteVideoProps } from './model';

import './WaterWasteVideo.scss';

const WaterWasteVideo: FC<IWaterWasteVideoProps> = ({
  logos,
  video,
  textBlock,
  decorativeElementTopLeft,
  decorativeElementBottomLeft,
  decorativeElementBottomRight,
}) => (
  <div className="waste-video">
    <div className="waste-video__container">
      <div className="waste-video__logo--container">
        <GatsbyImage
          className="waste-video__logo"
          image={logos[0].properties.image}
          alt={logos[0].properties.imageAlt}
          objectFit="contain"
          isLazyLoading={false}
        />
      </div>
      <div className="waste-video__video">
        <Video {...video[0].properties} className="waste-video__video" />
      </div>

      <div className="waste-video__text-block">
        <DangerouslySetInnerHtml className="waste-video__text-block" html={textBlock} />
      </div>
    </div>

    <GatsbyImage
      className="waste-video__decorative-top-left"
      image={decorativeElementTopLeft[0].properties.image}
      alt={decorativeElementTopLeft[0].properties.imageAlt}
      isLazyLoading={false}
    />

    <GatsbyImage
      className="waste-video__decorative-bottom-left"
      image={decorativeElementBottomLeft[0].properties.image}
      alt={decorativeElementBottomLeft[0].properties.imageAlt}
      objectFit="contain"
      isLazyLoading={false}
    />

    <GatsbyImage
      className="waste-video__decorative-bottom-right"
      image={decorativeElementBottomRight[0].properties.image}
      alt={decorativeElementBottomRight[0].properties.imageAlt}
      isLazyLoading={false}
    />
  </div>
);

export const query = graphql`
  fragment FragmentWaterWasteVideo on TWaterWasteVideo {
    properties {
      logos {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 394) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      video {
        properties {
          description
          time
          vimeoLink
          youtubeLink
          previewImage {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 795, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          btnAriaLabel
        }
      }
      decorativeElementTopLeft {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 254) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      decorativeElementBottomLeft {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 346) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      decorativeElementBottomRight {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 310) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      textBlock
    }
    structure
  }
`;

export default WaterWasteVideo;
