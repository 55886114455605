import React, { FC } from 'react';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import Button from 'common/Button';
import WCCard from '../WCCard';
import { IWCStart } from '../../models';
import './WCStart.scss';

const WCStart: FC<IWCStart> = ({ startPage, handlePlay, handleAutoPlay }) => {
  const { text, cta, cards, labelCheckbox } = startPage?.[0].properties;

  return (
    <div className="wc-start" data-testid="wc-start">
      <div className="wc-start__background">
        <div className="wc-start__wave">
          <div className="wc-start__wave-img" />
        </div>
      </div>
      <div className="wc-start__foreground">
        <div className="wc-start__description">
          {text && <DangerouslySetInnerHtml className="wc-start__text" html={text} />}
          {labelCheckbox && (
            <label
              htmlFor="wc-auto-play"
              className="wc-start__form-checkbox-group wc-form-component__form-checkbox-group"
            >
              <input onChange={handleAutoPlay} type="checkbox" name="policy" id="wc-auto-play" />
              <span className="checkbox" />
              <DangerouslySetInnerHtml html={labelCheckbox} />
            </label>
          )}
          {!!cta.length && (
            <Button
              variant="white"
              ariaLabel={cta[0].properties?.ariaLabel}
              classes="wc-start__cta"
              onClick={handlePlay}
            >
              {cta[0].properties.label}
            </Button>
          )}
        </div>
        {!!cards.length && (
          <div className="wc-start__cards">
            {cards.map(({ properties: { image, text: cardText } }) => (
              <WCCard text={cardText} image={image} classes="wc-start__card" />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default WCStart;
