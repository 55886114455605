import React, { FC } from 'react';
import GatsbyImage from 'common/GatsbyImage';
import { IWCLoading } from '../../models';
import './Loading.scss';

const Loading: FC<IWCLoading> = ({ loading }) => {
  const { loadingMessage, image } = loading?.[0]?.properties;

  return (
    <div className="wc-loading" data-testid="wc-loading">
      <div className="wc-loading__background">
        <div className="wc-loading__wave">
          <div className="wc-loading__wave-img" />
          <div className="wc-loading__wave-img2" />
        </div>
      </div>
      <div className="wc-loading__foreground">
        <div className="wc-loading__description">
          {image?.length ? (
            <div className="wc-loading__image">
              <GatsbyImage
                image={image[0].properties.image}
                objectFit="contain"
                className="wc-loading__image-img"
                alt={image[0].properties.imageAlt}
                isLazyLoading
              />
            </div>
          ) : null}
          {loadingMessage && <div className="wc-loading__text">{loadingMessage}</div>}
        </div>
      </div>
    </div>
  );
};

export default Loading;
