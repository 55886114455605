import React, { FC } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';
import ExternalLink from 'common/ExternalLink';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { IChallengeInfoSectionProps } from './model';

import './ChallengeInfoSection.scss';

const ChallengeInfoSection: FC<IChallengeInfoSectionProps> = ({
  title,
  description,
  footNote,
  link: [{ url, name }],
  challengeTitle,
  steps,
}) => (
  <section className="challenge-section">
    <div className="challenge-section__container">
      <div className="challenge-section__info-block">
        <DangerouslySetInnerHtml className="challenge-section__title" html={title} />
        <DangerouslySetInnerHtml className="challenge-section__description" html={description} />
        <DangerouslySetInnerHtml className="challenge-section__footNote" html={footNote} />
        <ExternalLink className="challenge-section__link" link={url} ariaLabel={name}>
          {name}
        </ExternalLink>
      </div>

      <div className="challenge-section__steps-block">
        <DangerouslySetInnerHtml
          className="challenge-section__challenge-title"
          html={challengeTitle}
        />
        <ul className="challenge-section__steps-list">
          {steps.map(
            ({
              properties: {
                title: stepTitle,
                image: [
                  {
                    properties: { image, imageAlt },
                  },
                ],
                description: stepDescription,
              },
            }) => (
              <li key={stepTitle} className="challenge-section__steps-item">
                <DangerouslySetInnerHtml
                  className="challenge-section__steps-item-title"
                  html={stepTitle}
                />
                <GatsbyImage
                  className="challenge-section__steps-item-image"
                  image={image}
                  alt={imageAlt}
                  objectFit="contain"
                />
                <DangerouslySetInnerHtml
                  className="challenge-section__steps-item-description"
                  html={stepDescription}
                />
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  </section>
);

export const query = graphql`
  fragment FragmentChallengeInfoSection on TChallengeInfoSection {
    properties {
      title
      description
      footNote
      link {
        name
        url
      }
      challengeTitle
      steps {
        properties {
          title
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 140, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          description
        }
      }
    }
    structure
  }
`;

export default ChallengeInfoSection;
