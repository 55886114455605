import React, { FC } from 'react';
import GatsbyImage from 'common/GatsbyImage';
import { DangerouslySetInnerHtml } from 'layout';
import classNames from 'classnames';
import { IWCCard } from '../../models';
import './WCCard.scss';

const WCCard: FC<IWCCard> = ({ text, image, classes }) => (
  <div className={classNames('wc-card', { [`${classes}`]: classes })} data-testid="wc-card">
    {text && <DangerouslySetInnerHtml className="wc-card__text" html={text} />}
    {image?.length ? (
      <div className="wc-card__image">
        <GatsbyImage
          image={image[0].properties.image}
          objectFit="contain"
          className="wc-card__image-img"
          alt={image[0].properties.imageAlt}
          isLazyLoading
        />
      </div>
    ) : null}
  </div>
);

export default WCCard;
