import React, { FC, useEffect, useState } from 'react';
import { DangerouslySetInnerHtml } from 'layout';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';

import './WCFormComponent.scss';
import { submitFormData } from 'components/SignUp/actions';
import { initialFormState } from 'components/SignUp/formConstants';
import { NEWSLETTER_SIGN_UP } from 'components/SignUp/constants';
import { IWCForm } from '../../models';

const WCFormComponent: FC<IWCForm> = ({ form }) => {
  const {
    description,
    formImage,
    labelEmail,
    labelLastName,
    labelFirstName,
    disclaimer,
    checkboxes,
    submitButton,
    ariaButton,
    errorMessage,
    formConfig,
  } = form?.[0]?.properties || {};

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [formState, setFormState] = useState(initialFormState);

  const { isSending, isError, isSent } = formState;
  const isCDSMode = formConfig?.[0]?.properties?.isCDSMode;

  useEffect(() => {
    if (isSent) {
      handleGoToStage('share');
    }
  }, [isSent]);

  const [formData, setFormData] = useState({
    first_name: '',
    lastName: '',
    email: '',
    accepts_marketing: true,
    marketing_opt_in_level: 'confirmed_opt_in',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e?.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: newValue,
    }));
    setFormState((prevVal) => ({ ...prevVal, isError: false }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const checkboxesArr = checkboxes.map(({ properties: { isRequired, businessId } }, index) => ({
      businessId,
      checked: !!formData[`checkbox-${index}`],
      isRequired,
    }));

    const checkboxesCheck = checkboxes?.length
      ? !checkboxesArr.some((el) => el.isRequired && !el.checked)
      : true;

    if (!checkboxesCheck) return;

    if (!executeRecaptcha) {
      return;
    }

    const recaptchaResponse = await executeRecaptcha(NEWSLETTER_SIGN_UP);

    const formDataSubmit = {
      firstName: formData.first_name,
      email: formData.email,
      lastName: formData.lastName,
      acceptsMarketing: formData.accepts_marketing,
      marketingOptInLevel: formData.marketing_opt_in_level,
    };

    await submitFormData({
      setFormState,
      formConfig,
      isCDSMode,
      url: process.env.GATSBY_CDP_LAMBDA_URL,
      formData: formDataSubmit,
      recaptchaResponse,
    });
  };

  return (
    <div className="wc-form-component" data-testid="wc-form-component">
      <div className="wc-form-component__top">
        {formImage?.length ? (
          <div className="wc-form-component__image">
            <GatsbyImage
              image={formImage[0].properties.image}
              className="wc-form-component__image-img"
              alt={formImage[0].properties.imageAlt}
              objectFit="contain"
            />
          </div>
        ) : null}
        <DangerouslySetInnerHtml html={description} className="wc-form-component__description" />
      </div>
      <form className="wc-form-component__form" action="#" onSubmit={handleSubmit}>
        {labelFirstName && (
          <div className="wc-form-component__form-group">
            <label htmlFor="first_name">{labelFirstName}</label>
            <input
              onChange={handleChange}
              value={formData.first_name}
              name="first_name"
              id="first_name"
              data-testid="first_name"
              type="text"
              required
            />
          </div>
        )}
        {labelLastName && (
          <div className="wc-form-component__form-group ">
            <label htmlFor="last_name">{labelLastName}</label>
            <input
              onChange={handleChange}
              value={formData.lastName}
              name="lastName"
              id="last_name"
              data-testid="first_name"
              type="text"
              required
            />
          </div>
        )}
        {labelEmail && (
          <div className="wc-form-component__form-group">
            <label htmlFor="email">{labelEmail}</label>
            <input
              onChange={handleChange}
              value={formData.email}
              name="email"
              id="email"
              data-testid="email"
              type="email"
              required
            />
          </div>
        )}
        <div className="wc-form-component__form-group--rest">
          {isError && !!errorMessage && (
            <p
              className="wc-form-component__error"
              aria-errormessage={errorMessage}
              live-region="alert"
            >
              {errorMessage}
            </p>
          )}
          {disclaimer ? (
            <DangerouslySetInnerHtml className="wc-form-component__disclaimer" html={disclaimer} />
          ) : null}
          {checkboxes?.length &&
            checkboxes.map(({ properties: { isRequired, label } }, index) => (
              <label
                htmlFor={`wc-form-policy-${index}`}
                className="wc-form-component__form-checkbox-group"
                key={`wc-form-policy-${index}`}
              >
                <input
                  onChange={handleChange}
                  type="checkbox"
                  name={`checkbox-${index}`}
                  id={`wc-form-policy-${index}`}
                  required={isRequired}
                />
                <span className="checkbox" />
                <DangerouslySetInnerHtml html={label} />
              </label>
            ))}
          {submitButton ? (
            <Button
              disabled={isSending || isSent}
              ariaLabel={ariaButton}
              type="submit"
              variant="primary"
              classes="wc-form-component__form__button"
              isLoading={isSending}
            >
              {submitButton}
            </Button>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default WCFormComponent;
