import React, { FC, useState } from 'react';
import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import classNames from 'classnames';
import { IWCChallengeCard } from '../../models';
import './WCChallengeCard.scss';

const WCChallengeCard: FC<IWCChallengeCard> = ({
  previewLabel,
  image,
  challengeCardLabel,
  challengeCardSuffix,
  challengeCardNumber,
  challengeDescription,
  handleSelectChallenge,
  sharingTitle,
  sharingDescription,
  classes,
  type = 'preview',
  dropNumber,
  dropNumberSuffix,
  dropDescription,
}) => {
  const [isActive, setIsActive] = useState(false);
  const clickHandler = () => {
    setIsActive(true);
    handleSelectChallenge &&
      handleSelectChallenge({
        challengeCardLabel,
        challengeCardSuffix,
        challengeCardNumber,
        challengeDescription,
        sharingTitle,
        sharingDescription,
        image,
        dropNumber,
        dropNumberSuffix,
        dropDescription,
      });
  };
  const Element = handleSelectChallenge ? 'button' : 'div';

  return (
    <Element
      onClick={clickHandler}
      className={classNames('wc-card-challenge', {
        btn: handleSelectChallenge,
        'btn--white': handleSelectChallenge,
        'wc-card-challenge--is-active': isActive,
        [`${classes}`]: classes,
        [`wc-card-challenge--${type}`]: type,
      })}
      data-testid="wc-card-challenge"
    >
      {type === 'preview' ? (
        <>
          <span className="wc-logo" />
          {previewLabel && (
            <DangerouslySetInnerHtml
              className="wc-card-challenge__previewLabel"
              html={previewLabel}
            />
          )}
          {image?.length ? (
            <div className="wc-card-challenge__image">
              <GatsbyImage
                image={image[0].properties.image}
                objectFit="contain"
                className="wc-card-challenge__image-img"
                alt={image[0].properties.imageAlt}
                isLazyLoading
              />
            </div>
          ) : null}
        </>
      ) : (
        <>
          <span className="wc-logo" />
          {challengeCardLabel && (
            <DangerouslySetInnerHtml
              className="wc-card-challenge__label"
              html={challengeCardLabel}
            />
          )}
          {challengeCardNumber && (
            <span className="wc-card-challenge__number">{challengeCardNumber}</span>
          )}
          {challengeCardSuffix && (
            <DangerouslySetInnerHtml
              className="wc-card-challenge__suffix"
              html={challengeCardSuffix}
            />
          )}
        </>
      )}
    </Element>
  );
};

export default WCChallengeCard;
