import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import { DangerouslySetInnerHtml, Container } from 'layout';
import Button from 'common/Button';

import { ICampaignCardsListProps } from './model';
import './CampaignCardsList.scss';

const CampaignCardsList: FC<ICampaignCardsListProps> = ({
  title,
  cards,
  cta,
  description,
  sectionTheme,
  fileLink,
}) => (
  <div
    data-testid="campaign-cards-list"
    className={classnames('campaign-cards-list', {
      [`campaign-cards-list--${sectionTheme}`]: sectionTheme,
    })}
  >
    <Container fluid>
      <DangerouslySetInnerHtml className="campaign-cards-list__title" html={title} />
      <div className="campaign-cards-list__cards">
        {cards.map((card) => (
          <div key={card.properties.title} className="campaign-cards-list__card">
            <DangerouslySetInnerHtml
              className="campaign-cards-list__card-title"
              html={card.properties.title}
            />
            <div className="campaign-cards-list__card-text">
              <DangerouslySetInnerHtml
                className="campaign-cards-list__card-subTitle"
                html={card.properties.subTitle}
              />
              <DangerouslySetInnerHtml
                className="campaign-cards-list__card-description"
                html={card.properties.description}
              />
            </div>
          </div>
        ))}
      </div>
      {cta?.map(({ properties: { link, ariaLabel = '' } }) => {
        const { name, url, target } = link?.[0] || {};

        return (
          <Button to={url} ariaLabel={ariaLabel} variant="primary" target={target}>
            {name}
          </Button>
        );
      })}
      {fileLink?.length ? (
        <div className="campaign-cards-list__file">
          <Button
            classes="campaign-cards-list__file-btn"
            to={fileLink[0].properties.link.url}
            ariaLabel={fileLink[0].properties.ariaLabel || ''}
            target="_blank"
            variant="link"
          >
            {fileLink[0]?.properties?.name}
          </Button>
        </div>
      ) : null}
      <DangerouslySetInnerHtml className="campaign-cards-list__description" html={description} />
    </Container>
  </div>
);

export const query = graphql`
  fragment FragmentCampaignCardsList on TCampaignCardsList {
    properties {
      title
      cards {
        properties {
          title
          subTitle
          description
        }
      }
      cta {
        properties {
          ariaLabel
          link {
            name
            url
            target
          }
        }
      }
      fileLink {
        properties {
          link {
            url
          }
          name
          ariaLabel
        }
      }
      description
      sectionTheme
    }
    structure
  }
`;

export default CampaignCardsList;
