import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { isValidUrl } from 'utils/functions';

import WaterChallengeCounter from './components/WaterChallengeCounter';

import { IWaterChallengeBannerBannerProps } from './model';

import './WaterChallengeBanner.scss';

const WaterChallengeBanner: FC<IWaterChallengeBannerBannerProps> = ({
  title,
  description,
  accentText,
  image,
  bgImage,
  counterAPIUrl,
  counterTitle,
  daysTitle,
  totalDays,
}) => (
  <div
    className={classNames('water-challenge-banner', {
      [`water-challenge-banner--with-counter`]: counterAPIUrl,
    })}
  >
    <div className="water-challenge-banner__container">
      <GatsbyImage
        className="water-challenge-banner__image"
        image={image[0].properties.image}
        alt={image[0].properties.imageAlt}
        objectFit="contain"
        isLazyLoading={false}
      />
      <DangerouslySetInnerHtml className="water-challenge-banner__accent-text" html={accentText} />
      <DangerouslySetInnerHtml className="water-challenge-banner__title" html={title} />
      <DangerouslySetInnerHtml className="water-challenge-banner__description" html={description} />
      {isValidUrl(counterAPIUrl) ? (
        <WaterChallengeCounter
          counterAPIUrl={counterAPIUrl}
          counterTitle={counterTitle}
          daysTitle={daysTitle}
          totalDays={totalDays}
        />
      ) : null}
    </div>
    <GatsbyImage
      image={bgImage[0].properties.image}
      alt={bgImage[0].properties.imageAlt}
      isLazyLoading={false}
      style={{ position: 'absolute', inset: '0' }}
    />
  </div>
);

export const query = graphql`
  fragment FragmentWaterChallengeBanner on TWaterChallengeBanner {
    properties {
      title
      description
      accentText
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 680, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      bgImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      counterAPIUrl
      counterTitle
      daysTitle
      totalDays
    }
    structure
  }
`;

export default WaterChallengeBanner;
