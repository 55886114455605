import React, { FC, useState, useRef, useEffect } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { graphql } from 'gatsby';
import { SignUpBlockTypes } from '@shared/types';

import { Container } from 'layout';
import { isBrowser } from 'utils/browser';

import './SignUpBlock.scss';
import { submitFormData } from 'components/SignUp/actions';
import { initialFormState } from 'components/SignUp/formConstants';
import { NEWSLETTER_SIGN_UP } from 'components/SignUp/constants';
import Form from './components/Form';
import ThankYou from './components/ThankYou';

const SignUpBlockChildren: FC<SignUpBlockTypes.IData> = ({
  description,
  fieldDisclaimer,
  labelFirstName,
  labelLastName,
  labelEmail,
  disclaimer,
  labelCheckbox,
  submitButton,
  ariaButton,
  message,
  cta,
  thankImage,
  errorMessage,
  formConfig,
  personalizeAdvertising,
  collapsibleCheckboxText,
  callbackFunction,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const ref = useRef(null);

  const [formState, setFormState] = useState(initialFormState);

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    policy: false,
  });

  const { isCDSMode, isCheckboxRequired } = formConfig?.[0]?.properties || {};

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const recaptchaResponse = await executeRecaptcha(NEWSLETTER_SIGN_UP);

    const formDataSubmit = {
      firstName: formData.first_name,
      email: formData.email,
      lastName: formData.last_name,
      policy: formData.policy,
    };

    await submitFormData({
      setFormState,
      formConfig,
      isCDSMode,
      url: process.env.GATSBY_CDP_LAMBDA_URL,
      callbackFunction,
      formData: formDataSubmit,
      recaptchaResponse,
    });
  };

  return (
    <div ref={ref} className="sign-up section--bg-white" data-testid="sign-up">
      <Container fluid className={!formState.isSent ? 'sign-up--container' : ''}>
        {!formState.isSent ? (
          <Form
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
            formState={formState}
            description={description}
            button={submitButton}
            ariaButton={ariaButton}
            labelCheckbox={labelCheckbox}
            labelEmail={labelEmail}
            labelFirstName={labelFirstName}
            labelLastName={labelLastName}
            fieldDisclaimer={fieldDisclaimer}
            disclaimer={disclaimer}
            errorMessage={errorMessage}
            setFormState={setFormState}
            collapsibleCheckboxText={collapsibleCheckboxText}
            personalizeAdvertising={personalizeAdvertising}
            isCheckboxRequired={isCheckboxRequired}
          />
        ) : null}
        {formState.isSent ? <ThankYou message={message} cta={cta} thankImage={thankImage} /> : null}
      </Container>
    </div>
  );
};

const SignUpBlock = (props) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const observer =
    isBrowser() &&
    new window.IntersectionObserver(([entry]) => {
      if (!show && entry.isIntersecting) {
        setShow(true);
      }
    });

  useEffect(() => {
    observer?.observe(ref.current);

    return () => {
      observer?.disconnect();
    };
  }, []);

  return (
    <div ref={ref}>
      {show ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.GATSBY_GOOGLE_CAPTCHA_PUBLIC}
          scriptProps={{ async: true, defer: false, appendTo: 'body' }}
        >
          <SignUpBlockChildren {...props} />
        </GoogleReCaptchaProvider>
      ) : (
        <SignUpBlockChildren {...props} />
      )}
    </div>
  );
};

export const query = graphql`
  fragment FragmentSignUpBlock on TSignUpBlock {
    properties {
      description
      fieldDisclaimer
      labelFirstName
      labelLastName
      labelEmail
      disclaimer
      labelCheckbox
      submitButton
      ariaButton
      message
      personalizeAdvertising
      collapsibleCheckboxText
      cta {
        properties {
          link {
            url
            target
            name
          }
          ariaLabel
        }
      }
      thankImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      errorMessage
      formConfig {
        properties {
          headers
          body
          cdp
          revisionIds {
            BusinessId
            RevisionId
            ConsentDesc
            MandatoryInd
            ConsentAcceptedInd
            AgreementDate
            ActivityDate
          }
          isCDSMode
          isCheckboxRequired
        }
      }
    }
    structure
  }
`;

export default SignUpBlock;
