import React, { FC, memo, useEffect, useRef } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import GatsbyImage from 'common/GatsbyImage';
import dataLayerEventPush from 'utils/dataLayerEventPush';
import { DATA_LAYER_WATER_CALCULATOR } from 'utils/constants';
import WCFormComponent from '../WCFormComponent';
import { IWCForm } from '../../models';
import './WCForm.scss';

const { GATSBY_GOOGLE_CAPTCHA_PUBLIC } = process.env;

const WCForm: FC<IWCForm> = ({ isDesktop, form, handleGoToStage }) => {
  const { image } = form?.[0]?.properties || {};
  const ref = useRef(null);

  useEffect(() => {
    ref.current?.firstElementChild.focus({
      preventScroll: true,
    });
    dataLayerEventPush(DATA_LAYER_WATER_CALCULATOR.SIGN_UP_SCREEN);
  }, []);

  return (
    <div ref={ref} className="wc-form" data-testid="wc-form">
      <div className="wc-form__background">
        {isDesktop ? (
          <div className="wc-form__image">
            {image?.length ? (
              <GatsbyImage
                image={image[0].properties.image}
                objectFit="cover"
                className="wc-form__image-img"
                alt={image[0].properties.imageAlt}
                isLazyLoading
              />
            ) : null}
          </div>
        ) : null}
        <div className="wc-form__wave" />
      </div>
      <div className="wc-form__foreground">
        <div className="water-calculator__container wc-form__container">
          <div className="wc-form__box">
            <GoogleReCaptchaProvider
              reCaptchaKey={GATSBY_GOOGLE_CAPTCHA_PUBLIC}
              scriptProps={{ async: true, defer: false, appendTo: 'body' }}
            >
              <WCFormComponent form={form} handleGoToStage={handleGoToStage} />
            </GoogleReCaptchaProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(WCForm);
