import React, { FC } from 'react';
import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import { DangerouslySetInnerHtml } from 'layout';
import { IThankYou } from '../model';

const ThankYou: FC<IThankYou> = ({ message, cta, thankImage }) => (
  <div className="sign-up__grid">
    <div className="sign-up__info sign-up__info--thank">
      {message ? <DangerouslySetInnerHtml className="sign-up__message" html={message} /> : null}

      {cta?.length ? (
        <Button
          classes="sign-up__message-cta"
          to={cta[0].properties.link[0].url}
          ariaLabel={cta[0].properties.ariaLabel}
          target={cta[0].properties.link[0].target}
          variant="primary"
        >
          {cta[0].properties.link[0].name}
        </Button>
      ) : null}
    </div>
    <div className="sign-up__media">
      {thankImage?.length ? (
        <GatsbyImage
          image={thankImage[0].properties.image}
          objectFit="contain"
          className="sign-up__media--image"
          alt={thankImage[0].properties.imageAlt}
        />
      ) : null}
    </div>
  </div>
);

export default ThankYou;
