import { useEffect, useState } from 'react';

interface IStickyObserver {
  (reference: RefObject<HTMLDivElement>): { offset: number; isShown: boolean; isVisible: boolean };
}

const useStickyObserver: IStickyObserver = (reference) => {
  const [offset, setOffset] = useState(0);
  const [isShown, setIsShown] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const stickyElm = reference.current;
    if (!stickyElm) return;
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsShown(e.boundingClientRect.top < 0);
        setOffset(e.intersectionRatio < 1 ? (e.intersectionRatio - 1) * -200 : 0);
        setIsVisible(e.intersectionRatio > 0.7);
      },
      { threshold: [0.7, 0.8, 0.9, 1] }
    );
    observer.observe(stickyElm as Element);

    return () => {
      observer.unobserve(stickyElm as Element);
    };
  }, []);

  return { offset, isShown, isVisible };
};
export default useStickyObserver;
