import React, { FC } from 'react';
import { IImageMapAreaFront } from '@shared/types/frontend/compositions/imageMapArea';

const ImageMapArea: FC<IImageMapAreaFront> = ({
  alt,
  shape,
  coords,
  activeState,
  tooltip,
  center,
  xPosition,
  yPosition,
  handleMouseEnter,
  handleMouseLeave,
  handleClick,
}) => {
  const activeSrc = activeState?.gatsbyImage?.childImageSharp?.original?.src;

  return (
    <>
      {activeSrc ? (
        <img
          className="image-map__area__hover"
          loading="lazy"
          aria-hidden="true"
          alt={alt}
          src={activeSrc}
        />
      ) : null}
      <area
        className="image-map__area"
        onMouseEnter={() => {
          handleMouseEnter(tooltip, center, activeSrc, xPosition?.[0], yPosition?.[0]);
        }}
        onMouseLeave={handleMouseLeave}
        onFocus={() => {
          handleMouseEnter(tooltip, center, activeSrc, xPosition?.[0], yPosition?.[0]);
        }}
        onBlur={handleMouseLeave}
        onClick={handleClick}
        href="#"
        {...{ shape, coords, alt }}
      />
    </>
  );
};

export default ImageMapArea;
