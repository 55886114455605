import React, { FC } from 'react';
import classNames from 'classnames';
import GatsbyImage from 'common/GatsbyImage';
import { Container, DangerouslySetInnerHtml } from 'layout';
import BreadCrumbs from 'common/BreadCrumbs';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { IPurposeBannerProps } from './model';
import './PurposeBanner.scss';

const PurposeBanner: FC<IPurposeBannerProps> = ({
  background,
  description,
  breadCrumbs = [],
  isSlimVersion,
  hiddenDescription,
  disclaimer,
  isBackgroundTransparent,
}) => {
  const bannerBackground = background?.[0]?.properties;
  const { isDesktop } = useScreenRecognition();

  return (
    <div
      className={classNames('purpose-banner', {
        'purpose-banner--slim': isSlimVersion,
      })}
    >
      {bannerBackground?.image ? (
        <GatsbyImage
          image={bannerBackground.image}
          alt={bannerBackground?.imageAlt}
          style={{ position: 'absolute' }}
          className={classNames('purpose-banner__background-img', {
            'top--position': hiddenDescription,
            'purpose-banner__background--transparent': isBackgroundTransparent,
          })}
          isLazyLoading={false}
          fadeIn={false}
        />
      ) : null}
      <Container
        fluid
        className={classNames({
          'purpose-banner__box': hiddenDescription,
        })}
      >
        <div
          className={classNames('purpose-banner__info', {
            'visual-hidden': hiddenDescription,
          })}
        >
          {breadCrumbs?.length ? <BreadCrumbs data={breadCrumbs} /> : null}
          {description ? (
            <DangerouslySetInnerHtml className="purpose-banner__description" html={description} />
          ) : null}
        </div>
        {isDesktop && disclaimer ? (
          <DangerouslySetInnerHtml className="purpose-banner__disclaimer" html={disclaimer} />
        ) : null}
      </Container>
      {isDesktop ? null : disclaimer ? (
        <DangerouslySetInnerHtml className="purpose-banner__disclaimer" html={disclaimer} />
      ) : null}
    </div>
  );
};

export default PurposeBanner;
