import React, { FC, useEffect, useState } from 'react';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { isValidNumber } from 'utils/functions';

import { waterCounterInitialState } from './constants';

import { IWaterChallengeCounterProps, IWaterChallengeAPIResponseData } from '../../model';

const WaterChallengeCounter: FC<IWaterChallengeCounterProps> = ({
  counterAPIUrl,
  counterTitle,
  daysTitle,
  totalDays,
}) => {
  const [results, setResults] = useState(waterCounterInitialState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rawData = await fetch(counterAPIUrl);
        const response: IWaterChallengeAPIResponseData = await rawData.json();

        if (!response.success || !response.data?.length) {
          throw new Error(`Error fetching data: ${response.message}`);
        }

        if (!isValidNumber(response.data[0].gallons_saved)) {
          throw new Error('Invalid data format for gallons_saved. Expected number.');
        }

        setResults({
          gallonsSaved: Number(response.data[0].gallons_saved).toString().split(''),
          daysSince: response.data[0].days_since,
        });
      } catch (err) {
        if (process.env.NODE_ENV === 'development') {
          console.error('[WaterChallengeCounter]:', err);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <div className="water-challenge-banner__counter-wrapper">
      <ul className="water-challenge-banner__counter-list">
        {results.gallonsSaved.map((number, idx) => (
          <li key={number + idx} className="water-challenge-banner__counter-list-item">
            {number}
          </li>
        ))}
      </ul>
      <DangerouslySetInnerHtml
        className="water-challenge-banner__counter-title"
        html={counterTitle}
      />
      <progress value={results.daysSince} max={totalDays} />
      <DangerouslySetInnerHtml className="water-challenge-banner__counter-title" html={daysTitle} />
    </div>
  );
};

export default WaterChallengeCounter;
