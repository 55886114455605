import React, { FC, useEffect, useState, useRef } from 'react';
import { getCookies, setCookies } from 'utils/cookies';
import { UmbracoPledgeSection } from '@shared/types/umbraco';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { graphql } from 'gatsby';
import Button from 'common/Button';
import Section from 'common/Section';
import { isBrowser } from 'utils/browser';

import { useCountUp } from 'react-countup';
import axios from 'axios';
import './PledgeSection.scss';

const cookieName = 'PLEDGE_IS_TAKEN';
const apiConfig = {
  usa: {
    baseUrl: 'https://hgy8owkflf.execute-api.us-east-1.amazonaws.com/prod',
    getUrl: '/statistics',
    postUrl: '/items',
    factor: 2995,
    authorization:
      'eyJhbGciOiJIUzI1NiJ9.ewoJInVybCI6Ind3dy5maW5pc2hkaXNod2FzaGluZy5jb20iCn0._GYDPMC-qaOoRkjqoCaqyzkAmf1s7QM6JI6zO_mSXIs',
    origin: 'https://www.finishdishwashing.com',
    payload: {
      pledge_sum: 2995,
    },
  },
  canada: {
    baseUrl:
      'https://cfwg4t7tvl.execute-api.eu-central-1.amazonaws.com/default/posejdon-counter-api',
    getUrl: '?country=canada',
    postUrl: '',
    factor: 7000,
    origin: 'https://www.finishdishwashing.ca',
    authorization: null,
    payload: {
      apiKey: 'dA4s6Us76KxaUyG99wcZ1rFiT2gvRWro',
      count: 1,
      country: 'canada',
    },
  },
};

const PledgeSection: FC<UmbracoPledgeSection.IData> = ({
  color,
  description,
  api,
  beforeCounter,
  afterCounter,
  cta,
  disclaimer,
  thankYouMessage,
  thankYouCTA,
}) => {
  const countUpRef = useRef(null);
  const { update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: 0,
    delay: 0,
    separator: ',',
    duration: 3,
  });
  const config = apiConfig[api[0]];
  const [number, setNumber] = useState(0);
  const [isSend, setIsSend] = useState(false);
  useEffect(() => {
    if (isBrowser()) {
      if (getCookies(cookieName)) {
        setIsSend(true);
      }
    }
  }, []);

  const getStatistics = () => {
    axios
      .get(`${config.baseUrl}${config.getUrl}`)
      .then((response) => response.data)
      .then((data) => {
        setNumber((data?.statistics?.[0]?.NumOfPladges || data?.count) * config.factor);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClick = () => {
    axios
      .post(`${config.baseUrl}${config.postUrl}`, config.payload, {
        headers: {
          Authorization: config.authorization,
          origin: config.origin,
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      })
      .then(() => {
        setIsSend(true);
        setNumber((prevNumber) => prevNumber + config.factor);
        setCookies(cookieName, true, '/', 365);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStatistics();
  }, []);

  useEffect(() => {
    update(number);
  }, [number]);

  return (
    <Section name="pledge" color={color}>
      <div className="pledge-section__main">
        {description && (
          <DangerouslySetInnerHtml className="pledge-section__description" html={description} />
        )}
        {api[0] ? (
          <div className="pledge-section__box">
            <p className="pledge-section__quantity">
              {beforeCounter && <span>{beforeCounter}</span>}
              <span ref={countUpRef} className="pledge-section__number" />
              {afterCounter && <span>{afterCounter}</span>}
            </p>

            {isSend ? (
              <div className="pledge-section__thank-you">
                {thankYouMessage && (
                  <DangerouslySetInnerHtml
                    className="pledge-section__thank-you-msg"
                    html={thankYouMessage}
                  />
                )}
                {thankYouCTA && (
                  <DangerouslySetInnerHtml
                    className="pledge-section__thank-you-cta"
                    html={thankYouCTA}
                  />
                )}
              </div>
            ) : null}
            {!isSend ? (
              <div className="pledge-section__form">
                {cta?.length > 0 && (
                  <Button
                    classes="pledge-section__button"
                    ariaLabel={cta[0].properties.ariaLabel}
                    variant="primary"
                    clickHandler={handleClick}
                  >
                    {cta[0].properties.link[0]?.name}
                  </Button>
                )}
                {disclaimer && (
                  <DangerouslySetInnerHtml
                    className="pledge-section__disclaimer"
                    html={disclaimer}
                  />
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </Section>
  );
};

export const query = graphql`
  fragment FragmentPledgeSection on TPledgeSection {
    properties {
      color {
        label
      }
      description
      api
      beforeCounter
      afterCounter
      cta {
        properties {
          link {
            name
          }
          ariaLabel
        }
      }
      disclaimer
      thankYouMessage
      thankYouCTA
    }
    structure
  }
`;

export default PledgeSection;
