import React, { FC } from 'react';
import { IWCSteps } from '../../models';
import './WCSteps.scss';

const WCSteps: FC<IWCSteps> = ({ steps, currentStep }) => (
  <div className="wc-steps" data-testid="wc-steps">
    <div className="wc-steps__container">
      <progress className="wc-steps__bar" value={currentStep} max={steps} />
      <span className="wc-steps__text" aria-hidden="true">
        <span className="wc-steps__text-current">{currentStep + 1}</span>
        <span className="wc-steps__text-rest">{` / ${steps}`}</span>
      </span>
    </div>
  </div>
);

export default WCSteps;
