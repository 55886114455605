import { DangerouslySetInnerHtml } from 'layout';
import React, { FC, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { UmbracoImageMap } from '@shared/types/umbraco';
import useMapResize from './useMapResize';
import ImageMapArea from './ImageMapArea';
import './ImageMap.scss';

const DefaultTooltipData = {
  content: '',
  center: [0, 0],
  hidden: true,
  hover: null,
  xPosition: '',
  yPosition: '',
};

const ImageMap: FC<UmbracoImageMap.IData> = ({
  caption,
  name,
  image: [
    {
      properties: { image, imageAlt },
    },
  ],
  map,
  fixedPosition,
}) => {
  const { src, width, height } = image?.gatsbyImage?.childImageSharp?.original;
  const [imageWidth, setImageWidth] = useState<number>(0);
  const imageRef = useRef(null);
  const { areas } = useMapResize(map, width, height, imageWidth);

  const [tooltip, setTooltip] = useState(DefaultTooltipData);

  useEffect(() => {
    setTimeout(() => {
      setImageWidth(imageRef?.current?.clientWidth);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!imageRef?.current?.clientWidth) {
      return;
    }

    setTooltip(() => DefaultTooltipData);
    setImageWidth(imageRef?.current?.clientWidth);
  }, [imageRef?.current?.clientWidth]);

  const handleMouseEnter = (content, center, activeSrc, xPosition, yPosition) => {
    setTooltip((prevState) => ({
      ...prevState,
      hidden: false,
      content,
      center,
      hover: activeSrc || null,
      xPosition,
      yPosition,
    }));
  };

  const handleMouseLeave = () => {
    setTooltip((prevState) => ({
      ...prevState,
      hidden: true,
      hover: null,
    }));
  };

  const handleClick = (e) => {
    e.preventDefault();
  };

  const handleTooltipMouseEnter = () => {
    setTooltip((prevState) => ({
      ...prevState,
      hidden: false,
    }));
  };

  const handleTooltipMouseLeave = () => {
    setTooltip((prevState) => ({
      ...prevState,
      hidden: true,
    }));
  };

  return (
    <div className="image-map" data-testid="image-map">
      <div className="image-map__image">
        <img
          {...{ width, height }}
          loading="lazy"
          src={tooltip.hover || src}
          alt={imageAlt}
          useMap={`#${name}`}
          ref={imageRef}
          className="image-map__image-img"
        />
        {map?.length ? (
          <map name={name}>
            {areas.map((area, idx) => {
              const key = area.alt + idx;

              return (
                <ImageMapArea
                  handleMouseLeave={handleMouseLeave}
                  handleMouseEnter={handleMouseEnter}
                  handleClick={handleClick}
                  key={key}
                  {...area}
                />
              );
            })}
          </map>
        ) : null}
        {!fixedPosition ? (
          <div className="image-map__tooltip--live-region" aria-live="polite">
            <DangerouslySetInnerHtml
              style={{ left: `${tooltip.center[0]}%`, top: `${tooltip.center[1]}%` }}
              hidden={tooltip.hidden}
              html={tooltip.content}
              onMouseLeave={handleTooltipMouseLeave}
              onMouseEnter={handleTooltipMouseEnter}
              className={classnames(
                'image-map__tooltip',
                'image-map__tooltip--absolute',
                { [`image-map__tooltip--x-${tooltip.xPosition}`]: tooltip.xPosition },
                { [`image-map__tooltip--y-${tooltip.yPosition}`]: tooltip.yPosition }
              )}
            />
          </div>
        ) : null}
      </div>
      {caption ? <p className="image-map__caption">{caption}</p> : null}
      {fixedPosition ? (
        <div className="image-map__tooltip--live-region" aria-live="polite">
          <DangerouslySetInnerHtml
            style={{ left: `${tooltip.center[0]}%`, top: `${tooltip.center[1]}%` }}
            hidden={tooltip.hidden}
            html={tooltip.content}
            onMouseLeave={handleTooltipMouseLeave}
            onMouseEnter={handleTooltipMouseEnter}
            className={classnames(
              'image-map__tooltip',
              'image-map__tooltip--fixed',
              { [`image-map__tooltip--x-${tooltip.xPosition}`]: tooltip.xPosition },
              { [`image-map__tooltip--y-${tooltip.yPosition}`]: tooltip.yPosition }
            )}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ImageMap;
