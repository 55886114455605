import React, { FC, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { UmbracoWaterCalculator } from '@shared/types/umbraco';
import useScreenRecognition from 'hooks/useScreenRecognition';
import WCStart from './components/WCStart';
import WCResult from './components/WCResult';
import useStickyObserver from './useStickyObserver';
import useWaterCalculator from './useWaterCalculator';
import Loading from './components/Loading';
import WCSlide from './components/WCSlide';
import WCThankYou from './components/WCThankYou';
import WCShare from './components/WCShare';
import WCChallenge from './components/WCChallenge';
import WCForm from './components/WCForm';
import './WaterCalculator.scss';

const WaterCalculator: FC<UmbracoWaterCalculator.IData> = ({
  startPage,
  loading,
  slides,
  thankYou,
  shareSlide,
  results,
  goBackBtn,
  pauseButton,
  goNextBtn,
  challengeCTA,
  exploreYourChallengesBtn,
  form,
}) => {
  const scrollEl = useRef<HTMLDivElement>(null);
  const { offset, isShown, isVisible } = useStickyObserver(scrollEl);
  const styles = {
    '--offset': isShown ? '0' : `${offset}px`,
  } as React.CSSProperties;
  const { isDesktop } = useScreenRecognition();
  const { message, thankImage, cta } = form?.[0]?.properties || {};

  const {
    handlePlay,
    handleSelectOption,
    handleNextSlide,
    handlePause,
    onNextSlide,
    handleSelectChallenge,
    handleGoToStage,
    handleAutoPlay,
    quizState: {
      stage,
      animationTrigger,
      currentSlide,
      steps,
      currentStep,
      focusTrapIsActive,
      isOptionSelected,
      isOptionPaused,
      result,
      challenges,
      selectedChallenge,
      autoPlay,
    },
  } = useWaterCalculator(slides, results);

  return (
    <div
      className={classNames('water-calculator', 'water-calculator--dark-theme')}
      data-testid="water-calculator"
      id="water-calculator"
    >
      <div
        ref={scrollEl}
        className={classNames('water-calculator__sticky', { 'is-visible': isVisible })}
        style={styles}
      >
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={animationTrigger}
            addEndListener={(node, done) => {
              node.addEventListener(
                'transitionend',
                (e) => {
                  if (node.id === e.target.id) {
                    done();
                  }
                },
                false
              );
            }}
            classNames="animation"
          >
            <div id="aw" aria-live="polite">
              {stage === 'start' && (
                <WCStart
                  startPage={startPage}
                  handlePlay={handlePlay}
                  handleAutoPlay={handleAutoPlay}
                />
              )}
              {stage === 'loading' && <Loading loading={loading} />}
              {stage === 'quiz' && (
                <WCSlide
                  {...{
                    currentSlide,
                    currentStep,
                    steps,
                    focusTrapIsActive,
                    isDesktop,
                    handleSelectOption,
                    handleNextSlide,
                    handlePause,
                    isOptionSelected,
                    isOptionPaused,
                    onNextSlide,
                    pauseButton,
                    goNextBtn,
                    autoPlay,
                  }}
                />
              )}
              {stage === 'result' && (
                <WCResult
                  {...{
                    result,
                    challenges,
                    isDesktop,
                    focusTrapIsActive,
                    handleSelectChallenge,
                    exploreYourChallengesBtn,
                  }}
                />
              )}
              {stage === 'challenge' && (
                <WCChallenge
                  {...{
                    selectedChallenge,
                    handleGoToStage,
                    isDesktop,
                    challengeCTA,
                    goBackBtn,
                    focusTrapIsActive,
                  }}
                />
              )}
              {stage === 'thank' && <WCThankYou {...{ isDesktop, thankYou, handleGoToStage }} />}
              {stage === 'share' && (
                <WCShare
                  {...{
                    isDesktop,
                    shareSlide,
                    selectedChallenge,
                    focusTrapIsActive,
                    message,
                    thankImage,
                    cta,
                  }}
                />
              )}
              {stage === 'form' && (
                <WCForm isDesktop={isDesktop} form={form} handleGoToStage={handleGoToStage} />
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentWaterCalculator on TWaterCalculatorSection {
    properties {
      startPage {
        properties {
          text
          cta {
            properties {
              label
              ariaLabel
            }
          }
          cards {
            properties {
              text
              image {
                properties {
                  image {
                    ...FragmentGatsbyProps
                    gatsbyImage {
                      childImageSharp {
                        fluid(maxWidth: 620) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  imageAlt
                }
              }
            }
          }
          labelCheckbox
        }
      }
      loading {
        properties {
          loadingMessage
          image {
            properties {
              image {
                ...FragmentGatsbyProps
              }
              imageAlt
            }
          }
        }
      }
      slides {
        properties {
          intro
          question
          image {
            properties {
              image {
                ...FragmentGatsbyProps
              }
              imageAlt
            }
          }
          options {
            properties {
              label
              score
              correctAnswer
              challenge {
                properties {
                  previewLabel
                  challengeCardLabel
                  challengeDescription
                  challengeCardSuffix
                  challengeCardNumber
                  sharingTitle
                  sharingDescription
                  dropNumber
                  dropNumberSuffix
                  dropDescription
                }
              }
            }
          }
          priority
        }
      }
      thankYou {
        properties {
          number
          numberSuffix
          title
          description
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 1980) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
          cta {
            properties {
              label
              ariaLabel
            }
          }
          extraImage {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
          extraDescription
        }
      }
      shareSlide {
        properties {
          title
          description
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 1980) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
          shareButtons {
            properties {
              isShow
              ariaLabel
              variant
            }
          }
        }
      }
      results {
        properties {
          description
          infoText
          card {
            properties {
              text
              image {
                properties {
                  image {
                    ...FragmentGatsbyProps
                    gatsbyImage {
                      childImageSharp {
                        fluid(maxWidth: 620) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  imageAlt
                }
              }
            }
          }
          targetScore
        }
      }
      form {
        properties {
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 1980) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
          title
          description
          formImage {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 155) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
          labelEmail
          labelLastName
          labelFirstName
          disclaimer
          checkboxes {
            properties {
              label
              isRequired
              businessId
            }
          }
          submitButton
          ariaButton
          message
          thankImage {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
          cta {
            properties {
              link {
                url
                target
                name
              }
              ariaLabel
            }
          }
          errorMessage
          formConfig {
            properties {
              headers
              body
              revisionIds {
                BusinessId
                RevisionId
                ConsentDesc
                MandatoryInd
                ConsentAcceptedInd
                AgreementDate
                ActivityDate
              }
              isCDSMode
            }
          }
        }
      }
      exploreYourChallengesBtn {
        properties {
          label
          ariaLabel
        }
      }
      challengeCTA {
        properties {
          label
          ariaLabel
        }
      }
      goBackBtn {
        properties {
          label
          ariaLabel
        }
      }
      pauseButton {
        properties {
          label
          ariaLabel
        }
      }
      goNextBtn {
        properties {
          label
          ariaLabel
        }
      }
    }
    structure
  }
`;

export default WaterCalculator;
