import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';
import useMatchMedia from 'hooks/useMatchMedia';

import { IResiduesSectionProps } from './model';

import './ResiduesSection.scss';

const ResiduesSection: FC<IResiduesSectionProps> = ({
  title,
  footnote,
  residuesItems,
  sectionTheme,
}) => {
  const isDesktop = useMatchMedia('(min-width: 768px)');

  return (
    <section
      className={classNames('residues', {
        [`residues--${sectionTheme}`]: sectionTheme,
      })}
    >
      <DangerouslySetInnerHtml className="residues__title" html={title} />
      <ul className="residues__list">
        {residuesItems.map(({ properties: { text, image, mobileImage } }) => {
          const guideImageToRender = isDesktop ? image : mobileImage;

          return (
            <li key={text} className="residues__list-item">
              <DangerouslySetInnerHtml className="residues__list-item-title" html={text} />
              <GatsbyImage
                className="residues__list-item-image"
                style={{ position: 'absolute' }}
                image={guideImageToRender[0].properties.image}
                alt={guideImageToRender[0].properties.imageAlt}
              />
            </li>
          );
        })}
      </ul>
      <DangerouslySetInnerHtml className="residues__footnote" html={footnote} />
    </section>
  );
};

export const query = graphql`
  fragment FragmentResiduesSection on TResiduesSection {
    properties {
      title
      footnote
      sectionTheme
      residuesItems {
        properties {
          text
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 620, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
          mobileImage {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 768, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
    }
    structure
  }
`;

export default ResiduesSection;
