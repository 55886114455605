import React, { FC } from 'react';
import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import { DangerouslySetInnerHtml } from 'layout';
import { UmbracoDownloadListSection } from '@shared/types/umbraco';
import './DownloadCard.scss';

const DownloadCard: FC<UmbracoDownloadListSection.IDownloadCardProps> = ({
  description,
  type,
  size,
  date,
  image,
  downloadButton,
  typeLabel,
  sizeLabel,
}) => (
  <div className="download-card">
    {image?.length ? (
      <div className="download-card__image">
        <GatsbyImage
          image={image[0].properties.image}
          className="download-card__image-img"
          alt={image[0].properties.imageAlt}
          useFocalPoint
        />
      </div>
    ) : null}
    <div className="download-card__description">
      {description ? (
        <DangerouslySetInnerHtml className="download-card__title" html={description} />
      ) : null}
      <div className="download-card__type-wrapper">
        {type && (
          <span>
            {typeLabel}
            <span className="download-card__type">{` ${type}`}</span>
          </span>
        )}
        {size && (
          <span>
            {sizeLabel}
            <span className="download-card__type">{` ${size}`}</span>
          </span>
        )}
        {date && <span>{date}</span>}
      </div>
    </div>
    {downloadButton?.[0]?.properties && (
      <Button
        target="_blank"
        ariaLabel={downloadButton[0].properties.ariaLabel}
        variant="secondary"
        to={downloadButton[0].properties.link.url}
        classes="download-card__button"
        download
      >
        {downloadButton[0].properties.title}
      </Button>
    )}
  </div>
);

export default DownloadCard;
