import React, { FC } from 'react';

import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import { DangerouslySetInnerHtml } from 'layout';

import { ICampaignCardProps } from './model';

import './CampaignCard.scss';

const CampaignCard: FC<ICampaignCardProps> = ({ background, icon, title, link, description }) => (
  <div data-testid="campaign-card" className="campaign-card">
    <div className="campaign-card__background">
      <GatsbyImage
        image={background?.[0]?.properties?.image}
        alt={background?.[0]?.properties.imageAlt}
        style={{ position: 'absolute' }}
        className="campaign-card__background-img"
        isLazyLoading
        fadeIn={false}
      />
      <div className="campaign-card__info">
        {icon?.[0]?.properties ? (
          <div className="campaign-card__icon">
            <GatsbyImage
              image={icon?.[0]?.properties?.image}
              alt={icon?.[0]?.properties.imageAlt}
              className="campaign-card__icon-img"
              isLazyLoading
              fadeIn={false}
            />
          </div>
        ) : null}
        <DangerouslySetInnerHtml className="campaign-card__title" html={title} />
        {link?.[0]?.url ? (
          <Button
            to={link[0].url}
            ariaLabel={link[0].name}
            variant="secondary"
            iconSuffix="external-link"
            classes="campaign-card__link"
          >
            {link[0].name}
          </Button>
        ) : null}
        <DangerouslySetInnerHtml className="campaign-card__description" html={description} />
      </div>
    </div>
  </div>
);

export default CampaignCard;
