import { IListAndAccordionItem } from '@shared/types/umbraco';
import ListPlusAccordion from 'common/ListPlusAccordion';
import Section from 'common/Section';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import './GridList.scss';

const GridList: FC<IListAndAccordionItem> = ({ color, title, sectionTheme, ...restProps }) => (
  <Section name="grid-list" color={color} intro={title} classes={`section--${sectionTheme}`}>
    <ListPlusAccordion sectionTheme={sectionTheme} {...restProps} />
  </Section>
);

export const query = graphql`
  fragment FragmenTImageTextGrid on TImageTextGrid {
    properties {
      color {
        value
        label
      }
      title
      infoList {
        properties {
          description
          icon
          iconAlignment
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
      accordion {
        ...FragmentFaqAccordionProps
      }
      columnsCount
      btnTitle
      ariaMore
      showInMenu
      anchorName
      anchorTitle
      roundImage
      additionalText
      fileLink {
        properties {
          link {
            url
          }
          name
          ariaLabel
        }
      }
      cta {
        properties {
          ariaLabel
          link {
            name
            url
            target
            queryString
          }
        }
      }
      imageCheck {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      bottomText
      sectionTheme
    }
    structure
  }
`;

export default GridList;
