import React, { FC } from 'react';
import { graphql } from 'gatsby';

import ExternalLink from 'common/ExternalLink';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { IWaterReportProps } from './model';

import './WaterReport.scss';

const WaterReport: FC<IWaterReportProps> = ({ title, text, link, linkText }) => (
  <div className="water-report">
    <div className="water-report__container">
      <DangerouslySetInnerHtml className="water-report__title" html={title} />
      <DangerouslySetInnerHtml className="water-report__text" html={text} />
      <ExternalLink className="water-report__link" link={link[0].url}>
        <DangerouslySetInnerHtml className="water-report__link-text" html={linkText} />
      </ExternalLink>
    </div>
  </div>
);

export const query = graphql`
  fragment FragmentWaterReport on TWaterReport {
    properties {
      title
      text
      linkText
      link {
        url
      }
    }
    structure
  }
`;

export default WaterReport;
