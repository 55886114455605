import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import Button from 'common/Button';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';
import useMatchMedia from 'hooks/useMatchMedia';

import { IChallengeBannerProps } from './model';

import './ChallengeBanner.scss';

const ChallengeBanner: FC<IChallengeBannerProps> = ({
  title,
  description,
  steps,
  link,
  footnote,
  background,
  mobileBackground,
  sectionTheme,
}) => {
  const isDesktop = useMatchMedia('(min-width: 992px)');

  const backgroundImage = isDesktop
    ? background?.[0]?.properties
    : mobileBackground?.[0]?.properties;

  const { name, url, target } = link?.[0] || {};

  return (
    <section
      className={classnames('challenge-banner', {
        [`challenge-banner--${sectionTheme}`]: sectionTheme,
      })}
    >
      <div className="challenge-banner__container">
        <DangerouslySetInnerHtml html={title} className="challenge-banner__title" />
        <ul className="challenge-banner__steps">
          {steps.map(({ properties: { text, image, description: stepDescription } }) => (
            <li key={text} className="challenge-banner__step">
              <p className="challenge-banner__step-text">{text}</p>
              <GatsbyImage
                className="challenge-banner__step-image"
                image={image?.[0].properties.image}
                alt={image?.[0].properties.imageAlt}
                objectFit="contain"
              />
              <DangerouslySetInnerHtml
                className="challenge-banner__step-description"
                html={stepDescription}
              />
            </li>
          ))}
        </ul>
        <DangerouslySetInnerHtml html={description} className="challenge-banner__description" />
        {name && url ? (
          <Button
            to={url}
            ariaLabel={name}
            variant="primary"
            iconSuffix="chevron-right"
            target={target}
            classes="challenge-banner__link"
          >
            {name}
          </Button>
        ) : null}
        <DangerouslySetInnerHtml html={footnote} className="challenge-banner__footnote" />
      </div>
      {backgroundImage?.image ? (
        <div className="challenge-banner__background">
          <GatsbyImage image={backgroundImage.image} alt={backgroundImage.imageAlt} />
        </div>
      ) : null}
    </section>
  );
};

export const query = graphql`
  fragment FragmentChallengeBanner on TChallengeBanner {
    properties {
      title
      description
      steps {
        properties {
          text
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
          description
        }
      }
      link {
        name
        url
        target
      }
      footnote
      background {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      mobileBackground {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 992, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      sectionTheme
    }
    structure
  }
`;

export default ChallengeBanner;
