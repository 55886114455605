import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Video from 'common/Video';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';

import { IVoteSectionProps } from './model';

import './VoteSection.scss';

const VoteSection: FC<IVoteSectionProps> = ({ voteIframeForm, video, teams }) => (
  <section className="vote-section">
    <div className="vote-section__container">
      <DangerouslySetInnerHtml className="vote-section__vote-iframe-form" html={voteIframeForm} />
      <Video {...video?.[0]?.properties} className="vote-section__video" />
      <ul className="vote-section__teams-list">
        {teams.map(({ properties: { image, imageAlt } }) => (
          <li key={image.fallbackUrl} className="vote-section__team">
            <GatsbyImage
              className="vote-section__team-image"
              image={image}
              alt={imageAlt}
              objectFit="contain"
              isLazyLoading={false}
            />
          </li>
        ))}
      </ul>
    </div>
  </section>
);

export const query = graphql`
  fragment FragmentVoteSection on TVoteSection {
    properties {
      voteIframeForm
      video {
        properties {
          description
          time
          vimeoLink
          youtubeLink
          previewImage {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 990, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageAlt
            }
          }
          btnAriaLabel
        }
      }
      teams {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 250, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;

export default VoteSection;
