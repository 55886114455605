import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { IRteSectionProps } from './model';

import './RteSection.scss';

const RteSection: FC<IRteSectionProps> = ({ text, sectionTheme }) => (
  <section
    className={classnames('rte-section', {
      [`rte-section--${sectionTheme}`]: sectionTheme,
    })}
  >
    <div className="rte-section__container">
      <DangerouslySetInnerHtml className="rte-section__text" html={text} />
    </div>
  </section>
);

export const query = graphql`
  fragment FragmentRteSection on TRteSection {
    properties {
      text
      sectionTheme
    }
    structure
  }
`;

export default RteSection;
