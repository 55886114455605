import React, { FC } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { IBrandsSectionProps } from './model';

import './BrandsSection.scss';

const BrandsSection: FC<IBrandsSectionProps> = ({ title, footnote, brands }) => (
  <div className="brands-section">
    <div className="brands-section__container">
      <div className="brands-section__title-with-images">
        <DangerouslySetInnerHtml className="brands-section__title" html={title} />
        <ul className="brands-section__list">
          {brands.map(({ properties: { image, imageAlt } }) => (
            <li key={image.url} className="brands-section__list-item">
              <GatsbyImage
                className="brands-section__list-item-image"
                image={image}
                alt={imageAlt}
                objectFit="contain"
              />
            </li>
          ))}
        </ul>
      </div>
      <DangerouslySetInnerHtml className="brands-section__footnote" html={footnote} />
    </div>
  </div>
);

export const query = graphql`
  fragment FragmentBrandsSection on TBrandsSection {
    properties {
      title
      footnote
      brands {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 250, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;

export default BrandsSection;
