import { isBrowser } from './browser';

const stripHtmlTags = (html: string): string => {
  if (!isBrowser()) return '';
  const div = document.createElement('div');
  div.innerHTML = html;

  return div.textContent || div.innerText || '';
};

const stripHtmlTagsBuild = (html: string): string => {
  if (!html) return '';

  return html.replace(/(<([^>]+)>)/gi, '');
};

export { stripHtmlTags, stripHtmlTagsBuild };
