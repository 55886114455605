import { UmbracoWCSlide, UmbracoWCResultSlide } from '@shared/types/umbraco/compositions';
import { useState } from 'react';
import { TWCState, TQuizData, TWCStage } from './models';

let timer;

const useWaterCalculator = (
  slides: UmbracoWCSlide.IStructure[],
  results: UmbracoWCResultSlide.IStructure[]
): {
  handleSelectOption: (data: TQuizData) => void;
  handleNextSlide: () => void;
  handlePause: () => void;
  quizState: TWCState;
  resetQuiz: () => void;
  handlePlay: () => void;
  onNextSlide: () => void;
  handleSelectChallenge: (obj: any) => void;
  handleGoToStage: (name: TWCStage) => void;
  handleAutoPlay: () => void;
} => {
  const [quizState, setQuizState] = useState({
    animationTrigger: false,
    stage: 'start' as TWCStage,
    steps: slides.length,
    currentStep: 0,
    isOptionSelected: false,
    isOptionPaused: false,
    currentSlide: slides[0],
    collectedData: [],
    focusTrapIsActive: false,
    result: results[0],
    challenges: [],
    selectedChallenge: {},
    autoPlay: true,
  });

  const calculateResult = () => {
    const finalScore = quizState.collectedData.reduce((sum, item) => sum + item.score, 0);
    const resultSlide = results.find((result) => {
      const [min, max] = result.properties.targetScore.split(',');

      return finalScore >= min && finalScore <= max;
    });
    const challenges = quizState.collectedData
      .filter((data) => Boolean(data.challenge.length))
      .sort((a, b) => {
        if (a.score < b.score) {
          if (a.priority < b.priority) {
            return -1;
          }

          return -1;
        }

        if (a.score === b.score) {
          if (a.priority < b.priority) {
            return -1;
          }
        }

        return 0;
      })
      .slice(0, 3)
      .map((item) => ({
        ...item.challenge[0].properties,
        image: item.image,
      }));

    return { resultSlide, challenges };
  };

  const switchToNextSlide = () => {
    if (quizState.currentStep === quizState.steps - 1) {
      // if (quizState.currentStep === 4) {
      const { resultSlide, challenges } = calculateResult();

      setQuizState((prevState) => ({
        ...prevState,
        stage: 'result',
        result: resultSlide,
        challenges,
        animationTrigger: !prevState.animationTrigger,
      }));
    } else {
      setQuizState((prevState) => ({
        ...prevState,
        currentStep: prevState.currentStep + 1,
        currentSlide: slides[prevState.currentStep + 1],
        animationTrigger: !prevState.animationTrigger,
      }));
    }
  };

  const handleSelectOption = ({ score, priority, challenge, image }) => {
    setQuizState((prevState) => ({
      ...prevState,
      isOptionSelected: true,
      collectedData: [...prevState.collectedData, { score, priority, challenge, image }],
    }));

    if (quizState.autoPlay) {
      timer = setTimeout(switchToNextSlide, 4000);
    }
  };

  const handleAutoPlay = () => {
    setQuizState((prevState) => ({
      ...prevState,
      autoPlay: !prevState.autoPlay,
    }));
  };

  const handleSelectChallenge = (obj) => {
    setQuizState((prevState) => ({
      ...prevState,
      selectedChallenge: { ...obj },
      animationTrigger: !prevState.animationTrigger,
      stage: 'challenge',
    }));
  };

  const handleGoToStage = (stageName) => {
    setQuizState((prevState) => ({
      ...prevState,
      animationTrigger: !prevState.animationTrigger,
      stage: stageName,
    }));
  };

  const handlePause = () => {
    setQuizState((prevState) => ({
      ...prevState,
      isOptionPaused: true,
    }));
    clearTimeout(timer);
  };

  const handleNextSlide = () => {
    clearTimeout(timer);
    switchToNextSlide();
  };

  const onNextSlide = () => {
    setQuizState((prevState) => ({
      ...prevState,
      isOptionSelected: false,
      isOptionPaused: false,
    }));
  };

  const resetQuiz = () => {
    setQuizState((prevState) => ({
      ...prevState,
      animationTrigger: !prevState.animationTrigger,
      stage: 'start',
      steps: slides.length,
      currentStep: 0,
      isOptionSelected: false,
      isOptionPaused: false,
      currentSlide: slides[0],
      collectedData: [],
      focusTrapIsActive: true,
    }));
  };

  const handlePlay = () => {
    setQuizState((prevState) => ({
      ...prevState,
      stage: 'loading',
      focusTrapIsActive: true,
      animationTrigger: !prevState.animationTrigger,
    }));

    setTimeout(() => {
      setQuizState((prevState) => ({
        ...prevState,
        stage: 'quiz',
        animationTrigger: !prevState.animationTrigger,
      }));
    }, 4000);
  };

  return {
    handleSelectOption,
    handleNextSlide,
    handlePause,
    handleSelectChallenge,
    handleGoToStage,
    quizState,
    resetQuiz,
    handlePlay,
    onNextSlide,
    handleAutoPlay,
  };
};

export default useWaterCalculator;
