import React, { FC, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Accordion from 'react-tiny-accordion';

import Button from 'common/Button';
import IconCustom from 'common/IconCustom';
import { Container, DangerouslySetInnerHtml } from 'layout';
import RelatedArticleCard from 'components/RelatedArticleCard';

import { UmbracoRelatedArticles } from '@shared/types/umbraco/compositions';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { IRelatedCampaignsProps } from './model';

import './RelatedCampaigns.scss';

const RelatedCampaigns: FC<IRelatedCampaignsProps> = ({
  title,
  limitDesktop,
  limitMobile,
  showMoreTitle,
  showMoreAriaLabel,
  items,
  relatedCampaigns,
}) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const onChangeIndex = (_, expanded: boolean, selectedIndex: number) => {
    setExpandedIndex(expanded ? selectedIndex : null);
  };

  const { isDesktop } = useScreenRecognition();

  const itemsToShow = useMemo(() => {
    const itemsLinks = items?.map(({ url }) => url);

    return (
      (
        relatedCampaigns.nodes?.map(({ tags, link, cardInfo }) => {
          const { description, image } = cardInfo?.[0]?.properties || {};

          return {
            tags,
            link,
            image,
            title: description,
          };
        }) as UmbracoRelatedArticles.IArticleCard[]
      ).sort((a, b) => itemsLinks.indexOf(a?.link) - itemsLinks.indexOf(b?.link)) || []
    );
  }, [relatedCampaigns]);

  const breakpointLimit = isDesktop ? limitDesktop : limitMobile;
  const limitToShow = showMoreTitle ? breakpointLimit : itemsToShow.length;

  const itemsToDisplay = itemsToShow.slice(0, limitToShow);
  const hiddenItems = showMoreTitle ? itemsToShow.slice(limitToShow) : [];

  return (
    <section className="related-campaigns-section section" data-testid="related-campaigns-section">
      <Container fluid>
        {title ? (
          <DangerouslySetInnerHtml className="related-campaigns-section__title" html={title} />
        ) : null}

        {itemsToDisplay.length ? (
          <div
            className={classNames('related-campaigns-section__grid', {
              [`col-${itemsToDisplay.length}`]: itemsToDisplay.length,
            })}
          >
            {itemsToDisplay.map((card) => (
              <RelatedArticleCard key={card.title} {...card} />
            ))}
          </div>
        ) : null}

        {hiddenItems.length ? (
          <Accordion
            className="related-campaigns-section__accordion"
            transitionDuration="300"
            onChange={onChangeIndex}
          >
            <div
              className="accordion__item"
              data-header={
                <Button
                  variant="icon"
                  classes="show-more__button"
                  ariaLabel={showMoreAriaLabel}
                  aria-expanded={expandedIndex}
                >
                  <IconCustom icon="minus" />
                  <IconCustom icon="plus" />
                  {showMoreTitle}
                </Button>
              }
            >
              <div
                className={classNames('related-campaigns-section__grid', {
                  [`col-${hiddenItems.length}`]: hiddenItems.length,
                })}
              >
                {hiddenItems.map((card) => (
                  <RelatedArticleCard key={card.title} {...card} />
                ))}
              </div>
            </div>
          </Accordion>
        ) : null}
      </Container>
    </section>
  );
};

export default RelatedCampaigns;

export const query = graphql`
  fragment FragmentRelatedCampaigns on TRelatedCampaigns {
    structure
    properties {
      title
      showMoreTitle
      showMoreAriaLabel
      limitMobile
      limitDesktop
      items {
        url
      }
    }
  }
`;
