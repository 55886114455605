import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';
import useMatchMedia from 'hooks/useMatchMedia';

import { ISuperBowlBannerProps } from './model';

import './SuperBowlBanner.scss';

const SuperBowlBanner: FC<ISuperBowlBannerProps> = ({
  title,
  imageMobile,
  imageDesktop,
  productImageMobile,
  productImageDesktop,
  productText,
}) => {
  const isDesktop = useMatchMedia('(min-width: 992px)');
  const image = isDesktop ? imageDesktop : imageMobile;
  const productImage = isDesktop ? productImageDesktop : productImageMobile;

  return (
    <section className="superbowl-banner">
      <div className="superbowl-banner__container">
        <DangerouslySetInnerHtml className="superbowl-banner__title" html={title} />
        <div className="superbowl-banner__image-wrapper">
          <GatsbyImage
            className="superbowl-banner__image"
            image={image[0].properties.image}
            alt={image[0].properties.imageAlt}
            objectFit="contain"
            isLazyLoading={false}
          />
        </div>
        <div className="superbowl-banner__product-wrapper">
          <GatsbyImage
            className="superbowl-banner__product-image"
            image={productImage[0].properties.image}
            alt={productImage[0].properties.imageAlt}
            objectFit="contain"
            isLazyLoading={false}
          />
          <DangerouslySetInnerHtml className="superbowl-banner__product-text" html={productText} />
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentSuperBowlBanner on TSuperBowlBanner {
    properties {
      title
      imageMobile {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 580, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      imageDesktop {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 580, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      productText
      productImageMobile {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 210, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      productImageDesktop {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 300, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;

export default SuperBowlBanner;
