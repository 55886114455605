import React, { FC, useState, useRef } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { isValid } from 'iban';
import axios from 'axios';
import { Container } from 'layout';
import { ReceiptFormTypes } from '@shared/types';
import Form from './Form';
import ThankYou from './ThankYou';
import { config } from '../constants';
import { bodyCreator } from '../bodyCreator';

const FormWrapper: FC<ReceiptFormTypes.IData> = ({
  description,
  labelFirstName,
  labelLastName,
  labelEmail,
  labelShop,
  shop,
  brand,
  region,
  submitButton,
  ariaSubmitButton,
  labelBrand,
  labelRegion,
  fileTitle,
  labelFile,
  addressTitle,
  labelAddress,
  labelComplex,
  labelSuburb,
  labelProvince,
  labelCity,
  labelPostalCode,
  labelCheckBox,
  labelCell,
  privacyDisclaimer,
  cta,
  thankMessage,
  thankImage,
  errorMessage,
  contestName,
  revisionIds,
  formType = ['sa'],
  ibanValidationText,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const ref = useRef(null);

  const [formState, setFormState] = useState({
    isSent: false,
    isSending: false,
    isError: false,
  });

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    store: '',
    brand: '',
    cell: '',
    region: '',
    addressLine1: '',
    addressLine2: '',
    addreesLine3: '',
    city: '',
    stateCode: '',
    postalCode: '',
    policy: false,
  });

  const [file, setFile] = useState(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formType?.[0] === 'de' && ibanValidationText) {
      if (!isValid(formData.cell)) {
        return;
      }
    }

    const googleCaptcha = await executeRecaptcha('receiptForm');

    setFormState((oldState) => ({
      ...oldState,
      isSending: true,
    }));

    const url = `${process.env.GATSBY_RECEIPT_FORM_URL}/receipt`;

    const { headers } = config[formType?.[0] || 'sa'];

    if (window?.location?.search) {
      const params = new URLSearchParams(window.location.search);

      const sourceChannel = params.get('utm_medium');

      if (sourceChannel) {
        formData.sourceChannel = sourceChannel;
      }
    }

    const body = bodyCreator({ formType: formType?.[0] || 'sa', formData, revisionIds });

    const bodyFormData = new FormData();
    bodyFormData.append('headers', JSON.stringify(headers));
    bodyFormData.append('body', JSON.stringify(body));
    bodyFormData.append('brandName', 'finish');
    bodyFormData.append('contestName', contestName);
    bodyFormData.append('googleCaptcha', googleCaptcha);
    bodyFormData.append('file', file);

    axios
      .post(url, bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        setFormState((oldState) => ({
          ...oldState,
          isSent: true,
        }));
        ref.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
      })
      .catch((error) => {
        console.error(error?.response?.data);
        setFormState({
          isSent: false,
          isSending: false,
          isError: true,
        });
      });
  };

  return (
    <div ref={ref} className="receipt-form">
      <Container fluid className={!formState.isSent ? 'receipt-form--container' : ''}>
        {!formState.isSent ? (
          <Form
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
            setFormState={setFormState}
            formState={formState}
            labelShop={labelShop}
            shop={shop}
            brand={brand}
            region={region}
            description={description}
            labelEmail={labelEmail}
            labelFirstName={labelFirstName}
            labelLastName={labelLastName}
            labelBrand={labelBrand}
            labelRegion={labelRegion}
            fileTitle={fileTitle}
            labelFile={labelFile}
            addressTitle={addressTitle}
            labelAddress={labelAddress}
            labelComplex={labelComplex}
            labelSuburb={labelSuburb}
            labelProvince={labelProvince}
            labelCity={labelCity}
            labelPostalCode={labelPostalCode}
            labelCheckBox={labelCheckBox}
            labelCell={labelCell}
            privacyDisclaimer={privacyDisclaimer}
            errorMessage={errorMessage}
            submitButton={submitButton}
            ariaSubmitButton={ariaSubmitButton}
            file={file}
            setFile={setFile}
            formType={formType?.[0] || 'sa'}
            ibanValidationText={ibanValidationText}
          />
        ) : null}
        {formState.isSent ? (
          <ThankYou message={thankMessage} cta={cta} thankImage={thankImage} />
        ) : null}
      </Container>
    </div>
  );
};

export default FormWrapper;
