import React, { FC } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';

import { IImageDividerProps } from './model';

const ImageDivider: FC<IImageDividerProps> = ({ image }) => (
  <GatsbyImage
    className="image-divider"
    image={image[0].properties.image}
    alt={image[0].properties.imageAlt}
  />
);

export const query = graphql`
  fragment FragmentImageDivider on TImageDivider {
    properties {
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;

export default ImageDivider;
