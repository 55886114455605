import React, { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { isBrowser } from 'utils/browser';
import { graphql } from 'gatsby';
import { setCookie, getCookie } from 'tiny-cookie';
import SignUpComponent from 'components/SignUp/components/SignUpComponent';
import IframeBlock from 'components/IframeBlock';
import Loader from 'common/Loader';

const { GATSBY_GOOGLE_CAPTCHA_PUBLIC } = process.env;

const CouponsSections = (props) => {
  const [isGetCookies, setIsGetCookies] = useState(false);
  const [isShowIframe, setIsShowIframe] = useState(false);

  useEffect(() => {
    if (isBrowser()) {
      const isSigned = getCookie('signUpForm', JSON.parse);
      setIsGetCookies(true);

      if (isSigned) {
        setIsShowIframe(true);
      }
    }
  }, []);

  const callbackFunction = () => {
    setCookie('signUpForm', 'true', { expires: '1Y' });
    setIsShowIframe(true);
  };

  const {
    title,
    description,
    labelFirstName,
    labelEmail,
    disclaimer,
    labelCheckbox,
    submitButton,
    ariaButton,
    errorMessage,
    alignImage,
    image,
    formConfig,
  } = props;

  return (
    <div>
      {!isGetCookies && <Loader />}
      {isGetCookies && !isShowIframe && (
        <GoogleReCaptchaProvider
          reCaptchaKey={GATSBY_GOOGLE_CAPTCHA_PUBLIC}
          scriptProps={{ async: true, defer: false, appendTo: 'body' }}
        >
          <SignUpComponent
            singupFormBaner={{
              title,
              description,
              labelFirstName,
              labelEmail,
              disclaimer,
              labelCheckbox,
              button: submitButton,
              ariaButton,
              errorMessage,
              formConfig,
            }}
            alignImage={alignImage}
            image={image}
            renderForm
            callbackFunction={callbackFunction}
          />
        </GoogleReCaptchaProvider>
      )}

      {isGetCookies && isShowIframe && props.iframeBlock?.[0]?.properties && (
        <IframeBlock {...props.iframeBlock?.[0]?.properties} />
      )}
    </div>
  );
};

export const query = graphql`
  fragment FragmentCouponsSection on TCouponsSection {
    properties {
      title
      description
      labelFirstName
      labelEmail
      disclaimer
      labelCheckbox
      submitButton
      ariaButton
      errorMessage
      alignImage
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      iframeBlock {
        ... on TIframeBlock {
          ...FragmentIframeBlock
        }
      }
      formConfig {
        properties {
          headers
          body
          cdp
          revisionIds {
            BusinessId
            RevisionId
            ConsentDesc
            MandatoryInd
            ConsentAcceptedInd
            AgreementDate
            ActivityDate
          }
          isCDSMode
        }
      }
    }
    structure
  }
`;

export default CouponsSections;
