import React, { FC, useEffect, useRef } from 'react';
import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import dataLayerEventPush from 'utils/dataLayerEventPush';
import { DATA_LAYER_WATER_CALCULATOR } from 'utils/constants';
import WCChallengeCard from '../WCChallengeCard';
import WCSlideControls from '../WCSlideControls';

import { IWCChallenge } from '../../models';
import './WCChallenge.scss';

const WCChallenge: FC<IWCChallenge> = ({
  isDesktop,
  selectedChallenge,
  handleGoToStage,
  challengeCTA,
  goBackBtn,
  focusTrapIsActive,
}) => {
  const {
    challengeCardLabel,
    challengeCardSuffix,
    challengeCardNumber,
    challengeDescription,
    dropNumber,
    dropNumberSuffix,
    dropDescription,
  } = selectedChallenge;

  const ref = useRef(null);

  useEffect(() => {
    if (focusTrapIsActive) {
      ref.current?.firstElementChild.focus({
        preventScroll: true,
      });
    }
    dataLayerEventPush(DATA_LAYER_WATER_CALCULATOR.CHALLENGE_DETAILS_SCREEN);
  }, []);

  return (
    <div ref={ref} className="wc-challenge" data-testid="wc-challenge">
      <div className="wc-challenge__background">
        {isDesktop ? (
          <div className="wc-challenge__drops">
            <div className="wc-challenge__drops-drop1" />
            <div className="wc-challenge__drops-drop2" />
            <div className="wc-challenge__drops-drop3" />
          </div>
        ) : (
          <div className="wc-challenge__wave">
            <div className="wc-challenge__wave-img" />
          </div>
        )}
      </div>
      <div className="wc-challenge__foreground">
        <WCSlideControls handleGoBack={() => handleGoToStage('result')} goBackBtn={goBackBtn} />
        <div className="water-calculator__container wc-challenge__container">
          <div className="wc-challenge__text-column">
            {challengeDescription && (
              <DangerouslySetInnerHtml
                html={challengeDescription}
                className="wc-challenge__description"
              />
            )}
            {isDesktop && challengeCTA.length && (
              <Button
                classes="wc-challenge__cta"
                variant="white"
                clickHandler={() => handleGoToStage('thank')}
                ariaLabel={challengeCTA[0]?.properties?.ariaLabel}
              >
                {challengeCTA[0]?.properties?.label}
              </Button>
            )}
            {isDesktop && dropNumber && (
              <div className="wc-challenge__drop-section">
                <div className="wc-challenge__drop">
                  <div className="wc-challenge__drop-number">{dropNumber}</div>
                  <div className="wc-challenge__drop-suffix">{dropNumberSuffix}</div>
                </div>
                <DangerouslySetInnerHtml
                  html={dropDescription}
                  className="wc-challenge__drop-description"
                />
              </div>
            )}
          </div>

          <WCChallengeCard
            type="full"
            {...{
              challengeCardLabel,
              challengeCardSuffix,
              challengeCardNumber,
            }}
          />
          {!isDesktop && challengeCTA?.length && (
            <Button
              classes="wc-challenge__cta"
              variant="white"
              clickHandler={() => handleGoToStage('thank')}
              ariaLabel={challengeCTA[0]?.properties?.ariaLabel}
            >
              {challengeCTA[0]?.properties?.label}
            </Button>
          )}
          {!isDesktop && dropNumber && (
            <div className="wc-challenge__drop-section">
              <div className="wc-challenge__drop">
                <div className="wc-challenge__drop-number">{dropNumber}</div>
                <div className="wc-challenge__drop-suffix">{dropNumberSuffix}</div>
              </div>
              <DangerouslySetInnerHtml
                html={dropDescription}
                className="wc-challenge__drop-description"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WCChallenge;
