import { isBrowser } from './browser';

const dataLayerEventPush = (event: string) => {
  const dataLayer = (isBrowser() && window.dataLayer) || [];

  dataLayer.push({
    event,
  });
};

export default dataLayerEventPush;
