import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import { Container, DangerouslySetInnerHtml } from 'layout';
import { VideoBackgroundSectionProps } from './models';
import './VideoBackgroundSection.scss';

const VideoBackgroundSection: FC<VideoBackgroundSectionProps> = ({
  textColor,
  description,
  videoFile,
}) => (
  <div
    className={classnames('video-background-section', {
      [`video-background-section--dark-theme`]: textColor?.label === 'white',
    })}
    data-testid="video-background-section"
  >
    <div className="video-background-section__video">
      <div className="video-background-section__overflow" />
      {videoFile?.url && (
        <video autoPlay loop muted>
          <source src={videoFile.url} type="video/mp4" />
          <track default kind="captions" />
        </video>
      )}
    </div>
    <Container>
      {description && (
        <DangerouslySetInnerHtml
          className="video-background-section__description"
          html={description}
        />
      )}
    </Container>
  </div>
);

export default VideoBackgroundSection;

export const query = graphql`
  fragment FragmentVideoBackgroundSection on TVideoBackgroundSection {
    properties {
      textColor {
        label
      }
      description
      videoFile {
        url
        fallbackUrl
      }
    }
    structure
  }
`;
