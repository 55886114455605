import React, { useState, useRef, useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { graphql } from 'gatsby';
import { isBrowser } from 'utils/browser';
import FormWrapper from './components/FormWrapper';
import './ReceiptForm.scss';

const ReceiptForm = (props) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const observer =
    isBrowser() &&
    new window.IntersectionObserver(([entry]) => {
      if (!show && entry.isIntersecting) {
        setShow(true);
      }
    });

  useEffect(() => {
    observer?.observe(ref.current);

    return () => {
      observer?.disconnect();
    };
  }, []);

  return (
    <div ref={ref} className="section--bg-white">
      {show ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.GATSBY_RECEIPT_GOOGLE_CAPTCHA}
          scriptProps={{ async: true, defer: false, appendTo: 'body' }}
        >
          <FormWrapper {...props} />
        </GoogleReCaptchaProvider>
      ) : (
        <FormWrapper {...props} />
      )}
    </div>
  );
};

export const query = graphql`
  fragment FragmentReceiptForm on TReceiptForm {
    properties {
      description
      labelFirstName
      labelLastName
      labelEmail
      labelShop
      shop {
        value
      }
      brand {
        value
      }
      region {
        value
      }
      submitButton
      ariaSubmitButton
      labelBrand
      labelRegion
      fileTitle
      labelFile
      addressTitle
      labelAddress
      labelComplex
      labelSuburb
      labelProvince
      labelCity
      labelPostalCode
      labelCheckBox
      labelCell
      privacyDisclaimer
      errorMessage
      thankMessage
      cta {
        properties {
          link {
            url
            target
            name
          }
          ariaLabel
        }
      }
      thankImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      contestName
      formType
      ibanValidationText
    }
    structure
  }
`;

export default ReceiptForm;
