import React, { FC } from 'react';

import Button from 'common/Button';
import { DangerouslySetInnerHtml } from 'layout';
import CheckboxField from 'common/CheckboxField/CheckboxField';

import { IForm } from '../model';

const Form: FC<IForm> = ({
  description,
  button,
  ariaButton,
  labelCheckbox,
  labelEmail,
  labelFirstName,
  labelLastName,
  fieldDisclaimer,
  disclaimer,
  setFormData,
  handleSubmit,
  formData,
  formState,
  errorMessage,
  setFormState,
  personalizeAdvertising,
  collapsibleCheckboxText,
  isCheckboxRequired,
}) => {
  const { isSending, isError } = formState;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e?.target;
    const newValue: string | boolean = type === 'checkbox' ? checked : value;

    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: newValue,
    }));

    setFormState((prevVal) => ({ ...prevVal, isError: false }));
  };

  return (
    <div className="sign-up__info">
      {description ? (
        <DangerouslySetInnerHtml className="sign-up__description" html={description} />
      ) : null}
      {fieldDisclaimer ? <p className="sign-up__field-disclaimer">{fieldDisclaimer}</p> : null}
      <form className="sign-up__form" action="#" onSubmit={handleSubmit}>
        <div className="sign-up__form-group sign-up__form-group--first">
          <label htmlFor="sign-up-first_name">{labelFirstName}</label>
          <input
            onChange={handleChange}
            value={formData.first_name}
            name="first_name"
            id="sign-up-first_name"
            type="text"
            required
          />
        </div>
        <div className="sign-up__form-group sign-up__form-group--last">
          <label htmlFor="sign-up-last_name">{labelLastName}</label>
          <input
            onChange={handleChange}
            value={formData.last_name}
            name="last_name"
            id="sign-up-last_name"
            type="text"
          />
        </div>
        <div className="sign-up__form-group sign-up__form-group--email">
          <label htmlFor="sign-up-email">{labelEmail}</label>
          <input
            onChange={handleChange}
            value={formData.email}
            name="email"
            id="sign-up-email"
            type="email"
            required
          />
        </div>

        <div className="sign-up__form-group--rest">
          {personalizeAdvertising ? (
            <DangerouslySetInnerHtml
              html={personalizeAdvertising}
              className="sign-up__personalize-adv"
            />
          ) : null}

          {labelCheckbox ? (
            <CheckboxField
              id="sign-up-policy"
              name="policy"
              handleChange={handleChange}
              value={formData.policy}
              isRequired={isCheckboxRequired}
              labelText={labelCheckbox}
              collapsedText={collapsibleCheckboxText}
              containerClassName="checkbox--blue"
            />
          ) : null}

          {isError && !!errorMessage && (
            <p className="sign-up__error" aria-errormessage={errorMessage} live-region="alert">
              {errorMessage}
            </p>
          )}
          <DangerouslySetInnerHtml className="sign-up__form__disclaimer" html={disclaimer} />
          {button ? (
            <Button
              disabled={isSending}
              ariaLabel={ariaButton}
              type="submit"
              variant="primary"
              isLoading={isSending}
            >
              {button}
            </Button>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default Form;
