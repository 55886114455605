import React, { FC } from 'react';
import './NativeDropdown.scss';

interface NativeDropdownProps {
  id: string;
  label: string;
  value: string;
  name: string;
  required?: boolean;
  options: { value: string }[];
  onChange: any;
  onBlur?: any;
}

const NativeDropdown: FC<NativeDropdownProps> = ({
  id,
  label,
  value,
  name,
  required,
  options,
  onChange,
}) => (
  <>
    <label className="native-dropdown__label" htmlFor={id}>
      {label}
    </label>
    <select
      className="native-dropdown"
      id={id}
      value={value}
      name={name}
      required={required}
      onChange={onChange}
    >
      <option value="" disabled>
        {label}
      </option>
      {options.map((el) => (
        <option key={el.value} value={el.value}>
          {el.value}
        </option>
      ))}
    </select>
  </>
);

export default NativeDropdown;
