import React, { FC, useCallback } from 'react';

import Button from 'common/Button';
import { ScrollToButtonProps } from './models';

import './ScrollToButton.scss';

const ScrollToButton: FC<ScrollToButtonProps> = ({ ariaTop, ariaBottom }) => {
  const scrollToTop = useCallback(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const scrollToBottom = useCallback(() => {
    window.scrollBy({
      top: document.documentElement.scrollHeight - window.innerHeight,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="scroll-to-buttons">
      <Button
        data-testid="scroll-to-top"
        onClick={scrollToTop}
        ariaLabel={ariaTop}
        variant="secondary"
        iconSuffix="chevron-down"
        classes="scroll-to-buttons__btn scroll-to-buttons__top"
      />
      <Button
        data-testid="scroll-to-bottom"
        onClick={scrollToBottom}
        ariaLabel={ariaBottom}
        variant="secondary"
        iconSuffix="chevron-down"
        classes="scroll-to-buttons__btn scroll-to-buttons__bottom"
      />
    </div>
  );
};

export default ScrollToButton;
