import React, { FC, useState } from 'react';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import classNames from 'classnames';
import { IWCOption } from 'components/WaterCalculator/models';
import './WCOption.scss';

const WCOption: FC<IWCOption> = ({
  label,
  correctAnswer,
  score,
  priority,
  challenge,
  handleSelectOption,
  image,
  isOptionSelected,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const handleButtonClick = () => {
    setIsSelected(true);
    handleSelectOption({ score, priority, challenge, image });
  };

  return (
    <div
      className={classNames('wc-option', {
        'wc-option--selected': isSelected,
      })}
      data-testid="wc-option"
    >
      <button
        type="button"
        role="option"
        aria-selected={isSelected}
        disabled={isSelected}
        className="wc-option__label btn btn--icon"
        onClick={handleButtonClick}
        {...(isOptionSelected && !isSelected ? { tabIndex: '-1' } : {})}
      >
        {label && <DangerouslySetInnerHtml className="wc-option__label-title" html={label} />}
        <div className="wc-logo" />
      </button>
      <div className="wc-option__answer" aria-hidden={!isSelected}>
        {correctAnswer && (
          <DangerouslySetInnerHtml className="wc-option__correct-answer" html={correctAnswer} />
        )}
        <div className="wc-logo" />
      </div>
    </div>
  );
};

export default WCOption;
