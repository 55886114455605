import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Section from 'common/Section';
import DownloadCard from 'components/DownloadCard';
import { UmbracoDownloadListSection } from '@shared/types/umbraco';
import './DownloadList.scss';

const DownloadList: FC<UmbracoDownloadListSection.IData> = ({
  color,
  intro,
  cards,
  typeLabel,
  sizeLabel,
}) => (
  <Section intro={intro} color={color} name="download-list">
    <div className="download-list__grid">
      {cards?.length &&
        cards.map(({ properties: { description, type, size, date, image, downloadButton } }) => (
          <DownloadCard
            key={type + size + date}
            description={description}
            type={type}
            size={size}
            date={date}
            image={image}
            downloadButton={downloadButton}
            typeLabel={typeLabel}
            sizeLabel={sizeLabel}
          />
        ))}
    </div>
  </Section>
);

export const query = graphql`
  fragment FragmentDownloadList on TDownloadList {
    properties {
      intro
      color {
        label
      }
      cards {
        properties {
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 360) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
          description
          type
          size
          date
          downloadButton {
            properties {
              title
              ariaLabel
              link {
                url
              }
            }
            structure
          }
        }
        structure
      }
      typeLabel
      sizeLabel
    }
    structure
  }
`;

export default DownloadList;
