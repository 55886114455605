import React, { FC } from 'react';
import BlockCarousel from 'components/BlockCarousel';
import { IWCCarousel } from 'components/WaterCalculator/models';
import './WCCarousel.scss';

const WCCarousel: FC<IWCCarousel> = ({ children }) => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    customPaging: () => <span className="btn btn--icon" />,
    arrows: false,
    centerMode: true,
  };

  return (
    <div className="wc-carousel" data-testid="wc-carousel">
      <BlockCarousel settings={settings}>{children}</BlockCarousel>
    </div>
  );
};

export default WCCarousel;
