import React, { FC } from 'react';

import GatsbyImage from 'common/GatsbyImage';
import { Container, DangerouslySetInnerHtml } from 'layout';

import { ICampaignSimpleBannerProps } from './model';

import './CampaignSimpleBanner.scss';

const CampaignSimpleBanner: FC<ICampaignSimpleBannerProps> = ({ background, header }) => {
  const bannerBackground = background?.[0]?.properties;

  return (
    <div className="campaign-simple-banner">
      {bannerBackground?.image ? (
        <GatsbyImage
          image={bannerBackground.image}
          alt={bannerBackground?.imageAlt}
          style={{ position: 'absolute' }}
          className="campaign-simple-banner__image"
          isLazyLoading={false}
          fadeIn={false}
        />
      ) : null}
      <Container fluid className="campaign-simple-banner__content">
        {header ? (
          <DangerouslySetInnerHtml className="campaign-simple-banner__header" html={header} />
        ) : null}
      </Container>
    </div>
  );
};

export default CampaignSimpleBanner;
