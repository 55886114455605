import { config } from './constants';
import { IBody } from './model';

export const bodyCreator = ({ formType, formData, revisionIds }) => {
  const { TierCode } = config[formType || 'sa'];
  const today = new Date();

  const {
    firstName,
    lastName,
    email,
    store,
    brand: brandVal,
    cell,
    region: regionVal,
    addressLine1,
    addressLine2,
    city,
    addreesLine3,
    stateCode,
    postalCode,
    sourceChannel,
  } = formData;

  const body: IBody = {
    TierCode,
    JsonExternalData: {},
  };

  if (formType === 'sa') {
    body.Addresses = [
      {
        AddressLine1: addressLine1,
        AddressLine2: addressLine2,
        City: city,
        AddreesLine3: addreesLine3,
        StateCode: stateCode,
        PostalCode: postalCode,
        DeliveryStatus: 'G',
        CountryCode: 'ZAF',
      },
    ];

    body.JsonExternalData.UnmappedAttributes = {
      email,
      name: firstName + lastName,
      Store: store,
      Brand: brandVal,
      Cell: cell,
      Region: regionVal,
      formId: 'finishPoseidonForm',
      formTime: today,
    };
  } else if (formType === 'de') {
    body.FirstName = firstName;
    body.LastName = lastName;

    body.JsonExternalData.UnmappedAttributes = {
      FirstName: firstName,
      LastName: lastName,
      bankAccount: cell,
      store,
      formTime: today,
    };

    if (sourceChannel) {
      body.Addresses = [
        {
          AddressLine2: 'QR',
          CountryCode: 'DEU',
          DeliveryStatus: 'G',
        },
      ];
    }
  }

  body.Emails = [
    {
      EmailAddress: email,
      DeliveryStatus: 'G',
    },
  ];

  body.JsonExternalData.Agreements = revisionIds || [];

  return body;
};
