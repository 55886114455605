import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { BazaarVoiceReviews } from '@phx-husky/bazaarvoice-components';

import { Container, DangerouslySetInnerHtml } from 'layout';

import { BazaarvoiceReviewsSectionProps } from './model';

import './BazaarvoiceReviewsSection.scss';

const BazaarvoiceReviewsSection: FC<BazaarvoiceReviewsSectionProps> = ({ title, bvProductId }) =>
  bvProductId ? (
    <Container fluid className="bazaarvoice-reviews" id="bv-reviews">
      {title ? (
        <DangerouslySetInnerHtml html={title} className="bazaarvoice-reviews__title" />
      ) : null}
      <BazaarVoiceReviews productId={bvProductId} />
    </Container>
  ) : null;

export const query = graphql`
  fragment FragmentBazaarvoiceReviews on TBazaarvoiceReviews {
    structure
    properties {
      title
      bvProductId
    }
  }
`;

export default BazaarvoiceReviewsSection;
