import React, { FC, useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Container } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import IconCustom from 'common/IconCustom';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { isBrowser } from 'utils/browser';
import { IWaterBannerProps } from './model';
import './WaterBanner.scss';

const WaterBanner: FC<IWaterBannerProps> = ({
  background,
  mobileBackground,
  titleImage,
  titleText,
  disclaimer = '',
  description = '',
  topImage,
  title,
  btnAriaLabel,
  isSubNavigationPresent,
}) => {
  const { isNotMobile } = useScreenRecognition();
  const [isFixed, setIsFixed] = useState(false);
  const desktopBannerBackground = background?.[0]?.properties;
  const mobileBannerBackground = mobileBackground?.[0]?.properties;
  const bannerBackground = isNotMobile ? desktopBannerBackground : mobileBannerBackground;

  const ref = useRef(null);

  const observer =
    isBrowser() &&
    new window.IntersectionObserver(
      ([entry]) => {
        const el = document.getElementById('water-banner__sticky-image');

        if (!entry.isIntersecting) {
          if (el && entry.boundingClientRect.top < 0) {
            const parentEl = el.parentElement;
            parentEl.style.paddingTop = `${el.offsetHeight}px`;
            setIsFixed(true);
          }
        } else if (el) {
          const parentEl = el.parentElement;
          parentEl.style.paddingTop = '0px';
          setIsFixed(false);
        }
      },
      {
        threshold: 0.4,
      }
    );

  useEffect(() => {
    observer?.observe(ref.current);

    return () => {
      observer?.disconnect();
    };
  }, []);

  const handleArrowClick = (e) => {
    e.preventDefault();
    document
      .getElementsByClassName('campaign-page__main-column')?.[0]
      ?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  return (
    <div
      ref={ref}
      className={classNames('water-banner', {
        'water-banner--is-fixed': isFixed,
        'water-banner--margin': isSubNavigationPresent,
      })}
      data-testid="water-banner"
    >
      <div className="water-banner__background">
        {bannerBackground?.image && (
          <GatsbyImage
            image={bannerBackground.image}
            alt={bannerBackground?.imageAlt}
            style={{ position: 'absolute' }}
            className="water-banner__background-img"
            isLazyLoading={false}
            fadeIn={false}
            useFocalPoint
          />
        )}

        {title && <h1 className="sr-only">{title}</h1>}

        <Container fluid style={{ height: '100%' }}>
          <div className="water-banner__grid" id="water-banner__grid">
            {topImage?.[0]?.properties?.image && (
              <div id="water-banner__sticky-image" className="water-banner__sticky-image">
                <GatsbyImage
                  image={topImage?.[0]?.properties?.image}
                  alt={topImage?.[0]?.properties?.imageAlt}
                  className="water-banner__sticky-image-img"
                  objectFit="contain"
                  isLazyLoading={false}
                />
              </div>
            )}
            <div className="water-banner__info">
              {titleImage?.[0]?.properties?.image ? (
                !titleText ? (
                  <div className="water-banner__image">
                    <GatsbyImage
                      image={titleImage?.[0]?.properties?.image}
                      role="presentation"
                      className="water-banner__image-img"
                      objectFit="contain"
                      isLazyLoading={false}
                    />
                  </div>
                ) : (
                  <div className="water-banner__image-with-text">
                    <div className="water-banner__image-with-text--img">
                      <GatsbyImage
                        image={titleImage?.[0]?.properties?.image}
                        role="presentation"
                        objectFit="contain"
                        isLazyLoading={false}
                      />
                    </div>
                    <DangerouslySetInnerHtml
                      className="water-banner__image-with-text--text"
                      html={titleText}
                    />
                  </div>
                )
              ) : null}
              {description ? (
                <DangerouslySetInnerHtml className="water-banner__description" html={description} />
              ) : null}
            </div>
            <a
              className="water-banner__button"
              href="#campaign-page__main-column"
              onClick={handleArrowClick}
              aria-label={btnAriaLabel}
            >
              <span className="water-banner__arrow">
                <IconCustom icon="arrow-down" />
              </span>
            </a>

            <div className="water-banner__disclaimer">
              {disclaimer ? (
                <DangerouslySetInnerHtml
                  className="water-banner__disclaimer-text"
                  html={disclaimer}
                />
              ) : null}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default WaterBanner;
