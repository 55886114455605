import React, { FC } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { IWaterWasteBannerProps } from './model';

import './WaterWasteBanner.scss';

const WaterWasteBanner: FC<IWaterWasteBannerProps> = ({
  title,
  logo,
  image,
  textBlock,
  backgroundImage,
  additionalLogo,
}) => (
  <div className="waste-banner">
    <div className="waste-banner--top">
      <div className="waste-banner__images waste-banner--container">
        <GatsbyImage
          className="waste-banner__image-logo"
          image={logo[0].properties.image}
          alt={logo[0].properties.imageAlt}
          objectFit="contain"
          isLazyLoading={false}
        />
        <GatsbyImage
          className="waste-banner__image-additional-logo"
          image={additionalLogo?.[0]?.properties?.image}
          alt={additionalLogo?.[0]?.properties?.imageAlt}
          objectFit="contain"
          isLazyLoading={false}
        />
        <DangerouslySetInnerHtml className="waste-banner__images-title" html={title} />
      </div>
      <GatsbyImage
        className="waste-banner__image"
        image={image[0].properties.image}
        alt={image[0].properties.imageAlt}
        objectFit="contain"
        isLazyLoading={false}
      />
      <GatsbyImage
        className="waste-banner__background-image"
        image={backgroundImage[0].properties.image}
        alt={backgroundImage[0].properties.imageAlt}
        isLazyLoading={false}
        objectPosition="center 72%"
      />
    </div>

    <DangerouslySetInnerHtml
      className="waste-banner__text-block waste-banner--container"
      html={textBlock}
    />
  </div>
);

export const query = graphql`
  fragment FragmentWaterWasteBanner on TWaterWasteBanner {
    properties {
      title
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 540, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      backgroundImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      logo {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 403, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      additionalLogo {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 403, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      textBlock
    }
    structure
  }
`;

export default WaterWasteBanner;
