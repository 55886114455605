import React, { FC } from 'react';
import classnames from 'classnames';
import Icon from 'common/IconCustom';
import './Input.scss';

interface InputProps {
  id: string;
  label: string;
  value: string;
  name: string;
  type?: string;
  required?: boolean;
  isError?: boolean;
  validationText?: string;
  onChange: any;
  onBlur?: any;
}

const Input: FC<InputProps> = ({
  id,
  label,
  value,
  name,
  type = 'text',
  required,
  isError,
  validationText,
  onChange,
  onBlur,
}) => (
  <>
    <label className={classnames('input-label', { 'input-label--error': isError })} htmlFor={id}>
      {label}
    </label>
    <input
      className={classnames('input', { 'input--error': isError })}
      id={id}
      value={value}
      name={name}
      type={type}
      required={required}
      onChange={onChange}
      onBlur={onBlur}
    />
    {isError && validationText && (
      <p className="error-message">
        <Icon icon="warning" />
        {validationText}
      </p>
    )}
  </>
);

export default Input;
