import React, { FC, useEffect, useRef } from 'react';
import { DangerouslySetInnerHtml } from 'layout';
import ShareButtons from 'components/ShareButtons';
import Button from 'common/Button';
import { stripHtmlTags } from 'utils/stripHtmlTags';
import GatsbyImage from 'common/GatsbyImage';
import dataLayerEventPush from 'utils/dataLayerEventPush';
import { DATA_LAYER_WATER_CALCULATOR } from 'utils/constants';
import { IWCShareSlide } from '../../models';
import './WCShare.scss';

const WCShare: FC<IWCShareSlide> = ({
  isDesktop,
  shareSlide,
  selectedChallenge,
  focusTrapIsActive,
  message,
  thankImage,
  cta,
}) => {
  const { image, shareButtons, title, description } = shareSlide?.[0]?.properties;
  const {
    sharingTitle,
    sharingDescription,
    image: imageCard,
    challengeCardNumber,
    challengeCardSuffix,
  } = selectedChallenge;

  const ref = useRef(null);

  useEffect(() => {
    if (focusTrapIsActive) {
      ref.current?.firstElementChild.focus({
        preventScroll: true,
      });
    }
    dataLayerEventPush(DATA_LAYER_WATER_CALCULATOR.LAST_SHARE_SCREEN);
  }, []);

  const shareConfig = {
    facebook2: {
      quote: stripHtmlTags(`${sharingTitle} ${sharingDescription}`),
    },
    twitter: {
      title: stripHtmlTags(`${sharingTitle} ${sharingDescription}`),
    },
    email: {
      subject: stripHtmlTags(sharingTitle),
      body: stripHtmlTags(sharingDescription),
    },
  };

  return (
    <div ref={ref} className="wc-share" data-testid="wc-share">
      <div className="wc-share__background">
        {isDesktop ? (
          <div className="wc-share__image">
            {image?.length ? (
              <GatsbyImage
                image={image[0].properties.image}
                objectFit="cover"
                className="wc-share__image-img"
                alt={image[0].properties.imageAlt}
                isLazyLoading
              />
            ) : null}
          </div>
        ) : null}
        <div className="wc-share__wave" />
      </div>
      <div className="wc-share__foreground">
        <div className="water-calculator__container wc-share__container">
          {!isDesktop && sharingTitle && (
            <DangerouslySetInnerHtml html={sharingTitle} element="h2" className="wc-share__title" />
          )}
          <div className="wc-share__box">
            {thankImage || message || cta ? (
              <div className="wc-share__extra-box">
                <div className="wc-share__thank-image">
                  {thankImage?.length ? (
                    <GatsbyImage
                      image={thankImage[0].properties.image}
                      objectFit="contain"
                      className="wc-share__thank-image-img"
                      alt={thankImage[0].properties.imageAlt}
                      isLazyLoading
                    />
                  ) : null}
                </div>
                {message && (
                  <DangerouslySetInnerHtml html={message} className="wc-share__message" />
                )}
                {cta?.[0]?.properties?.link?.[0]?.url ? (
                  <Button
                    to={cta[0].properties.link[0].url}
                    variant="primary"
                    ariaLabel={cta[0].properties.ariaLabel}
                  >
                    {cta[0].properties.link[0].name}
                  </Button>
                ) : null}
              </div>
            ) : null}

            <div className="wc-share__extra-box">
              <div className="wc-share__cards">
                <div className="wc-share__image-card">
                  {imageCard?.length ? (
                    <GatsbyImage
                      image={imageCard[0].properties.image}
                      objectFit="contain"
                      className="wc-share__image-img"
                      alt={imageCard[0].properties.imageAlt}
                      isLazyLoading
                    />
                  ) : null}
                </div>
                <div className="wc-share__number-card">
                  <span className="wc-logo" />
                  {!!challengeCardNumber && (
                    <span className="wc-share__number-card-num">{challengeCardNumber}</span>
                  )}
                  {challengeCardSuffix && (
                    <DangerouslySetInnerHtml
                      html={challengeCardSuffix}
                      className="wc-share__number-card-suffix"
                    />
                  )}
                </div>
              </div>
              {isDesktop && title && (
                <DangerouslySetInnerHtml html={title} element="h3" className="wc-share__title" />
              )}

              {description && (
                <DangerouslySetInnerHtml html={description} className="wc-share__description" />
              )}
              {shareButtons?.length ? (
                <ShareButtons shareButtons={shareButtons} config={shareConfig} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WCShare;
