import React, { FC } from 'react';
import { graphql } from 'gatsby';

import CampaignCard from './CampaignCard';
import { ICampaignCardsProps } from './model';

import './CampaignCards.scss';

const CampaignCards: FC<ICampaignCardsProps> = ({ cards }) => (
  <div data-testid="campaign-cards" className="campaign-cards">
    {cards?.map((card) => (
      <CampaignCard
        key={card.properties.title}
        background={card.properties.background}
        icon={card.properties.icon}
        title={card.properties.title}
        link={card.properties.link}
        description={card.properties.description}
      />
    ))}
  </div>
);

export const query = graphql`
  fragment FragmentCampaignCards on TCampaignCards {
    properties {
      cards {
        properties {
          title
          description
          link {
            name
            url
          }
          background {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 575, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          icon {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 140, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    structure
  }
`;

export default CampaignCards;
