export const config = {
  sa: {
    headers: {
      'Content-Type': 'application/json',
      'program-code': 'RBZAFFIN',
      'brand-org-code': 'RBZAFFIN',
      'account-source': 'ZAFFINECOMFORM',
      'Accept-Language': 'en-ZA',
    },
    TierCode: 'RBZAFFINTIER1',
    body: {
      TierCode: 'RBZAFFINTIER1',
      Emails: [
        {
          EmailAddress: 'email',
          DeliveryStatus: 'G',
        },
      ],
      Addresses: {
        AddressLine1: 'Delivery address',
        AddressLine2: 'Complex/Building',
        City: 'City',
        AddreesLine3: 'Suburb',
        StateCode: 'Province',
        PostalCode: 'PostalCode',
        DeliveryStatus: 'G',
      },
      JsonExternalData: {
        UnmappedAttributes: {
          email: 'email',
          name: 'full name',
          Store: 'Store',
          Brand: 'Finish',
          Cell: 'Cell',
          Region: 'Region',
          // PayslipURL: 'SAved file path',
          formId: 'finishPoseidonForm',
          // formTime: today,
        },
        Agreements: [],
      },
    },
  },
  de: {
    headers: {
      'Content-Type': 'application/json',
      'program-code': 'RBDEUFIN',
      'brand-org-code': 'Finish',
      'account-source': 'DEUFINECOMFORM',
      'Accept-Language': 'DEU',
    },
    TierCode: 'RBDEUFINTIER1',
    body: {
      TierCode: 'RBDEUFINTIER1',
      FirstName: 'Abc333456',
      LastName: 'Abc333456',
      Emails: [
        {
          EmailAddress: 'lovijeb343@get2israel.com',
          DeliveryStatus: 'G',
        },
      ],
      JsonExternalData: {
        UnmappedAttributes: {
          FirstName: 'Abc333456',
          LastName: 'Abc333456',
          IBAN: 1234567890,
          Invoice_url: 'https://via.placeholder.com/150',
          store: 'Rossmann',
          formTime: '2021-08-04T11:50:20.115Z',
        },
        Agreements: [],
      },
    },
  },
};
