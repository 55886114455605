import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';
import useMatchMedia from 'hooks/useMatchMedia';

import { IGuideSectionSectionProps } from './model';

import './GuideSection.scss';

const GuideSection: FC<IGuideSectionSectionProps> = ({ title, subtitle, image, mobileImage }) => {
  const isDesktop = useMatchMedia('(min-width: 768px)');
  const guideImageToRender = isDesktop ? image : mobileImage;

  return (
    <section className="guide">
      <div className="guide__container">
        <DangerouslySetInnerHtml className="guide__title" html={title} />
        <DangerouslySetInnerHtml className="guide__subtitle" html={subtitle} />
        <GatsbyImage
          className="guide__image"
          objectFit="contain"
          image={guideImageToRender[0].properties.image}
          alt={guideImageToRender[0].properties.imageAlt}
        />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentGuideSection on TGuideSection {
    properties {
      title
      subtitle
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1150, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      mobileImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 480, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;

export default GuideSection;
