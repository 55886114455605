import React, { FC } from 'react';
import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import { IWCQuestion } from '../../models';
import './WCQuestion.scss';

const WCQuestion: FC<IWCQuestion> = ({ question, image }) => (
  <div className="wc-question" data-testid="wc-question">
    <DangerouslySetInnerHtml className="wc-question__text" html={question} />
    {!!image?.length && (
      <div className="wc-question__image">
        <GatsbyImage
          image={image[0].properties.image}
          objectFit="contain"
          className="wc-question__image-img"
          alt={image[0].properties.imageAlt}
          isLazyLoading
        />
      </div>
    )}
  </div>
);

export default WCQuestion;
