import React, { FC, useRef, useCallback, useState } from 'react';
import { graphql } from 'gatsby';
import HTMLFlipBook from 'react-pageflip';

import Button from 'common/Button';
import { Container } from 'layout';
import Section from 'common/Section';
import useScreenRecognition from 'hooks/useScreenRecognition';
import EBookPage from './EBookPage';

import { IEBookProps, IDisableButton } from './models';
import './EBook.scss';

const EBook: FC<IEBookProps> = ({ buttonNext, buttonPrev, pages, text, showCover }) => {
  const [disableButton, setDisabledButton] = useState<IDisableButton>({ prev: true, next: false });
  const book = useRef<any>(null);

  const { isMobile } = useScreenRecognition();

  const handlerNextPage = useCallback(() => book.current.pageFlip().flipNext(), []);
  const handlePrevPage = useCallback(() => book.current.pageFlip().flipPrev(), []);
  const handleChangePage = useCallback((e) => {
    if (e.data === 0) setDisabledButton({ prev: true, next: false });
    if (e.data >= pages.length - 1) setDisabledButton({ prev: false, next: true });
    if (e.data > 0 && e.data < pages.length - 1) setDisabledButton({ prev: false, next: false });
  }, []);

  return (
    <Section intro={text} name="ebook" color={{ label: 'white' }}>
      <Container className="ebook-wrapper" aria-live="polite">
        <HTMLFlipBook
          width={350}
          height={500}
          ref={book}
          size={isMobile ? 'fixed' : 'stretch'}
          onFlip={handleChangePage}
          showCover={showCover && showCover === '1'}
        >
          {pages?.map(({ properties }, index) => (
            <EBookPage key={`${index}-ebookPage`} {...properties} />
          ))}
        </HTMLFlipBook>
        <div className="ebook-wrapper__button-group">
          <Button
            ariaLabel={buttonPrev[0].properties.ariaLabel}
            variant="primary-link"
            clickHandler={handlePrevPage}
            disabled={disableButton.prev}
          >
            {buttonPrev[0].properties.label}
          </Button>
          <Button
            ariaLabel={buttonNext[0].properties.ariaLabel}
            variant="primary-link"
            clickHandler={handlerNextPage}
            disabled={disableButton.next}
          >
            {buttonNext[0].properties.label}
          </Button>
        </div>
      </Container>
    </Section>
  );
};

export const query = graphql`
  fragment FragmentTEBook on TEBook {
    structure
    properties {
      text
      buttonNext {
        ...FragmentButton
      }
      buttonPrev {
        ...FragmentButton
      }
      showCover
      pages {
        properties {
          image {
            properties {
              imageAlt
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 564, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default EBook;
