import React, { FC, useEffect, useRef, memo, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { DangerouslySetInnerHtml } from 'layout';
import { IWCSlide } from 'components/WaterCalculator/models';
import dataLayerEventPush from 'utils/dataLayerEventPush';
import { DATA_LAYER_WATER_CALCULATOR } from 'utils/constants';
import WCCarousel from '../WCCarousel';
import WCQuestion from '../WCQuestion';
import WCSteps from '../WCSteps';
import WCSlideControls from '../WCSlideControls';
import WCOption from '../WCOption';
import './WCSlide.scss';

const WCSlide: FC<IWCSlide> = ({
  currentSlide: {
    properties: { intro, question, image, options, priority },
  },
  currentStep,
  steps,
  focusTrapIsActive,
  handleSelectOption,
  handleNextSlide,
  handlePause,
  isDesktop,
  isOptionSelected,
  isOptionPaused,
  onNextSlide,
  pauseButton,
  goNextBtn,
  autoPlay,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (focusTrapIsActive) {
      ref.current?.firstElementChild.focus({
        preventScroll: true,
      });
    }
    dataLayerEventPush(`${currentStep + 1}${DATA_LAYER_WATER_CALCULATOR.QUESTION_STEP}`);
  }, [focusTrapIsActive]);

  useLayoutEffect(() => {
    onNextSlide();
  }, []);

  return (
    <div
      ref={ref}
      className={classNames('wc-slide', {
        'option-paused': isOptionPaused,
        'autoplay-disabled': !autoPlay,
        'option-selected': isOptionSelected,
        'first-slide': currentStep === 0,
        'last-slide': currentStep === steps - 1,
      })}
      data-testid="wc-slide"
    >
      <div className="wc-slide__background">
        <div className="wc-slide__wave">
          <div className="wc-slide__wave-img" />
        </div>
      </div>

      <div className="wc-slide__foreground">
        <WCSteps {...{ steps, currentStep }} />
        {!isDesktop && (
          <>
            {question && <WCQuestion {...{ question, image }} />}
            {!!options?.length && (
              <WCCarousel>
                {options.map(({ properties: { label, correctAnswer, score, challenge } }) => (
                  <WCOption
                    key={label}
                    {...{
                      label,
                      correctAnswer,
                      score,
                      priority,
                      challenge,
                      handleSelectOption,
                      image,
                    }}
                  />
                ))}
              </WCCarousel>
            )}
          </>
        )}

        {isDesktop && (
          <div className="water-calculator__container">
            {intro && <DangerouslySetInnerHtml className="wc-slide__intro" html={intro} />}
            <div className="wc-slide__right-column">
              {question && <WCQuestion {...{ question, image }} />}

              {!!options?.length &&
                options.map(({ properties: { label, correctAnswer, score, challenge } }) => (
                  <WCOption
                    key={label}
                    {...{
                      label,
                      correctAnswer,
                      score,
                      priority,
                      challenge,
                      handleSelectOption,
                      image,
                      isOptionSelected,
                    }}
                  />
                ))}
            </div>
          </div>
        )}
        {isOptionSelected && (
          <WCSlideControls
            {...{ handlePause, handleNextSlide, pauseButton, goNextBtn, autoPlay, isOptionPaused }}
          />
        )}
      </div>
    </div>
  );
};

export default memo(WCSlide);
