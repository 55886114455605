import { useState, useMemo, useEffect } from 'react';

const getShapeCenter = (shape, coords, width, height) => {
  let x;
  let y;
  const coordsArray = coords.split(',');

  switch (shape[0]) {
    case 'circle': {
      x = (coordsArray[0] / width) * 100;
      y = (coordsArray[1] / height) * 100;
      break;
    }
    case 'rect': {
      x = ((coordsArray[0] + coordsArray[2]) / 2 / width) * 100;
      y = ((coordsArray[1] + coordsArray[3]) / 2 / height) * 100;
      break;
    }
    case 'poly': {
      const xs = coordsArray.filter((_, i) => i % 2 === 0);
      const ys = coordsArray.filter((_, i) => i % 2 === 1);
      x = ((Math.min(...xs) + Math.max(...xs)) / 2 / width) * 100;
      y = ((Math.min(...ys) + Math.max(...ys)) / 2 / width) * 100;
      break;
    }
    default: {
      break;
    }
  }

  return [x, y];
};

const useMapResize = (
  map: any[],
  width: number,
  height: number,
  imageWidth: number
): {
  areas: any[];
} => {
  const mapWithoutProps = useMemo(
    () =>
      map.map(({ properties: { coords, shape }, properties }) => ({
        ...properties,
        center: getShapeCenter(shape, coords, width, height),
      })),
    [map.length]
  );

  const [areas, setAreas] = useState(mapWithoutProps);

  const resizeMap = (imgWidth) => {
    if (!imgWidth || imgWidth === width) return;

    const percentage = imgWidth / width;
    const newAreas = mapWithoutProps.map((area) => {
      const newCoords = area.coords
        .split(',')
        .map((num) => Math.trunc(num * percentage))
        .join(',');

      return {
        ...area,
        coords: newCoords,
      };
    });

    setAreas(newAreas);
  };

  useEffect(() => {
    resizeMap(imageWidth);
  }, [imageWidth]);

  return {
    areas,
  };
};

export default useMapResize;
