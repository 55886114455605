import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import { IWCResult } from 'components/WaterCalculator/models';
import dataLayerEventPush from 'utils/dataLayerEventPush';
import { DATA_LAYER_WATER_CALCULATOR } from 'utils/constants';
import WCCarousel from '../WCCarousel';
import WCCard from '../WCCard';

import WCChallengeCard from '../WCChallengeCard';
import './WCResult.scss';

const WCResult: FC<IWCResult> = ({
  result: {
    properties: { description, card, infoText },
  },
  challenges,
  focusTrapIsActive,
  isDesktop,
  handleSelectChallenge,
  exploreYourChallengesBtn,
}) => {
  const ref = useRef(null);
  const [exploreActive, setExploreActive] = useState(false);
  const carouselItems = [
    {
      type: 'card' as string,
      ...card[0]?.properties,
    },
    ...challenges?.map((challenge) => ({ type: 'challenge', ...challenge })),
  ];

  useEffect(() => {
    if (focusTrapIsActive) {
      ref.current?.firstElementChild.focus({
        preventScroll: true,
      });
    }

    dataLayerEventPush(DATA_LAYER_WATER_CALCULATOR.RESULT_STEP);

    ref.current?.parentNode.classList.add('wc-result-animation');
  }, []);

  return (
    <div
      className={classNames('wc-result', {
        'wc-result--explore-active': exploreActive,
      })}
      data-testid="wc-result"
      ref={ref}
    >
      <div className="wc-result__background">
        <div className="wc-result__wave">
          <div className="wc-result__wave-img" />
        </div>
      </div>

      <div className="wc-result__foreground">
        {!isDesktop && (
          <>
            {description && (
              <DangerouslySetInnerHtml className="wc-result__intro" html={description} />
            )}
            {!!carouselItems?.length && (
              <WCCarousel>
                {carouselItems.map(
                  (
                    {
                      text,
                      image,
                      previewImage,
                      previewLabel,
                      challengeCardLabel,
                      challengeCardSuffix,
                      challengeCardNumber,
                      challengeDescription,
                      sharingTitle,
                      sharingDescription,
                      type,
                      dropNumber,
                      dropNumberSuffix,
                      dropDescription,
                    },
                    idx
                  ) => {
                    const Elemnt = type === 'card' ? WCCard : WCChallengeCard;
                    const key = type + idx;

                    return (
                      <Elemnt
                        classes="wc-result__carousel-card"
                        {...{
                          key,
                          text,
                          image,
                          previewImage,
                          previewLabel,
                          challengeCardLabel,
                          challengeCardSuffix,
                          challengeCardNumber,
                          challengeDescription,
                          handleSelectChallenge,
                          sharingTitle,
                          sharingDescription,
                          dropNumber,
                          dropNumberSuffix,
                          dropDescription,
                        }}
                      />
                    );
                  }
                )}
              </WCCarousel>
            )}
          </>
        )}

        {isDesktop && (
          <>
            {infoText && (
              <DangerouslySetInnerHtml className="wc-result__info-text" html={infoText} />
            )}
            <div className="water-calculator__container">
              {description && (
                <DangerouslySetInnerHtml className="wc-result__intro" html={description} />
              )}
              <div className="wc-result__right-column">
                {card?.length &&
                  card.map(({ properties: { text, image } }) => (
                    <WCCard classes="wc-result__card" key={text} {...{ text, image }} />
                  ))}

                {challenges?.length &&
                  challenges.map(
                    ({
                      previewLabel,
                      challengeDescription,
                      challengeCardLabel,
                      challengeCardSuffix,
                      challengeCardNumber,
                      sharingTitle,
                      sharingDescription,
                      image,
                      dropNumber,
                      dropNumberSuffix,
                      dropDescription,
                    }) => (
                      <WCChallengeCard
                        key={previewLabel}
                        type="preview"
                        classes="wc-result__card"
                        {...{
                          challengeCardLabel,
                          challengeCardSuffix,
                          challengeCardNumber,
                          challengeDescription,
                          previewLabel,
                          handleSelectChallenge,
                          image,
                          sharingTitle,
                          sharingDescription,
                          dropNumber,
                          dropNumberSuffix,
                          dropDescription,
                        }}
                      />
                    )
                  )}
              </div>
            </div>
          </>
        )}
        {exploreYourChallengesBtn?.length && isDesktop && (
          <div className="wc-result__controls">
            <div className="wc-result__controls-container">
              <Button
                classes="wc-result__explore"
                clickHandler={() => {
                  setExploreActive(true);
                }}
                variant="icon"
                iconSuffix="next"
                ariaLabel={exploreYourChallengesBtn[0].properties.ariaLabel}
              >
                {exploreYourChallengesBtn[0].properties.label}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WCResult;
