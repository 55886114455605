import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';

import { IHowTheMathWorksProps } from './model';

import './HowTheMathWorks.scss';

const HowTheMathWorks: FC<IHowTheMathWorksProps> = ({ title, math, footnote }) => (
  <section className="math">
    <DangerouslySetInnerHtml className="math__title" html={title} />
    <div className="math__calculation">
      <ul className="math__calculation-list">
        {math.map(({ properties: { title: mathTitle, image: mathImage, costs, numbers } }) => (
          <li key={mathTitle} className="math__calculation-list-item">
            <div className="math__calculation-list-item-image-with-text">
              <DangerouslySetInnerHtml
                className="math__calculation-list-item-title"
                html={mathTitle}
              />
              <GatsbyImage
                image={mathImage[0].properties.image}
                alt={mathImage[0].properties.imageAlt}
                objectFit="contain"
              />
            </div>

            <div className="math__calculation-list-item-costs-with-numbers">
              {costs?.length ? (
                <ul className="math__cost-list">
                  {costs.map(({ properties: { title: costItemTitle, image } }) => (
                    <li key={costItemTitle} className="math__cost-list-item">
                      <GatsbyImage
                        image={image[0].properties.image}
                        alt={image[0].properties.imageAlt}
                        objectFit="contain"
                      />
                      <DangerouslySetInnerHtml
                        className="math__cost-list-item-title"
                        html={costItemTitle}
                      />
                    </li>
                  ))}
                </ul>
              ) : null}

              <ul className="math__numbers-list">
                {numbers.map(({ properties: { price, note } }) => (
                  <li key={price} className="math__numbers-list-item">
                    <DangerouslySetInnerHtml
                      className="math__numbers-list-item-price"
                      html={price}
                    />
                    <DangerouslySetInnerHtml className="math__numbers-list-item-note" html={note} />
                  </li>
                ))}
              </ul>
            </div>
          </li>
        ))}
      </ul>
    </div>
    <DangerouslySetInnerHtml className="math__footnote" html={footnote} />
  </section>
);

export const query = graphql`
  fragment FragmentHowTheMathWorks on THowTheMathWorks {
    properties {
      title
      math {
        properties {
          title
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 512, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
          costs {
            properties {
              title
              image {
                properties {
                  image {
                    ...FragmentGatsbyProps
                    gatsbyImage {
                      childImageSharp {
                        fluid(maxWidth: 180, quality: 80) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  imageAlt
                }
              }
            }
          }
          numbers {
            properties {
              price
              note
            }
          }
        }
      }
      footnote
    }
    structure
  }
`;

export default HowTheMathWorks;
