import React, { FC, useEffect } from 'react';
import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import dataLayerEventPush from 'utils/dataLayerEventPush';

import { DATA_LAYER_WATER_CALCULATOR } from 'utils/constants';
import { IWCThankYou } from '../../models';
import './WCThankYou.scss';
import NextStage from './constants';

const WCThankYou: FC<IWCThankYou> = ({ isDesktop, thankYou, handleGoToStage }) => {
  const { cta, description, number, title, numberSuffix, image, extraDescription, extraImage } =
    thankYou?.[0]?.properties;

  useEffect(() => dataLayerEventPush(DATA_LAYER_WATER_CALCULATOR.THANK_YOU_SCREEN), []);

  return (
    <div className="wc-thank-you" data-testid="wc-thank-you">
      <div className="wc-thank-you__background">
        {isDesktop ? (
          <div className="wc-thank-you__image">
            {image?.length ? (
              <GatsbyImage
                image={image[0].properties.image}
                objectFit="cover"
                className="wc-thank-you__image-img"
                alt={image[0].properties.imageAlt}
                isLazyLoading
              />
            ) : null}
          </div>
        ) : null}
        <div className="wc-thank-you__wave" />
      </div>
      <div className="wc-thank-you__foreground">
        <div className="water-calculator__container wc-thank-you__container">
          <div className="wc-thank-you__box">
            <div className="wc-thank-you__drop">
              {!!number && <span className="wc-thank-you__drop-number">{number}</span>}
              {numberSuffix && (
                <DangerouslySetInnerHtml
                  html={numberSuffix}
                  element="span"
                  className="wc-thank-you__drop-text"
                />
              )}
            </div>
            {title && (
              <DangerouslySetInnerHtml html={title} element="h2" className="wc-thank-you__title" />
            )}
            {description && (
              <DangerouslySetInnerHtml html={description} className="wc-thank-you__description" />
            )}
            <div className="wc-thank-you__extra-box">
              {extraImage?.length ? (
                <div className="wc-thank-you__extra-box-image">
                  <GatsbyImage
                    image={extraImage[0].properties.image}
                    objectFit="contain"
                    className="wc-thank-you__extra-box-image-img"
                    alt={extraImage[0].properties.imageAlt}
                    isLazyLoading
                  />
                </div>
              ) : null}

              {extraDescription && (
                <DangerouslySetInnerHtml
                  html={extraDescription}
                  className="wc-thank-you__extra-box-description"
                />
              )}
              {cta?.length && (
                <Button
                  variant="primary"
                  classes="wc-thank-you__cta"
                  ariaLabel={cta?.[0]?.properties.ariaLabel}
                  clickHandler={() => handleGoToStage(NextStage)}
                >
                  {cta?.[0]?.properties.label}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WCThankYou;
