import React, { FC, useState } from 'react';
import { isValid } from 'iban';
import Button from 'common/Button';
import { DangerouslySetInnerHtml } from 'layout';
import { Input, NativeDropdown } from 'components/FormComponents';
import Icon from 'common/IconCustom';
import { IForm } from '../model';

const Form: FC<IForm> = ({
  formData,
  setFormData,
  handleSubmit,
  setFormState,
  formState,
  labelShop,
  labelBrand,
  labelRegion,
  shop: shopVal,
  brand: barndVal,
  region: regionVAl,
  description,
  labelEmail,
  labelFirstName,
  labelLastName,
  fileTitle,
  labelFile,
  addressTitle,
  labelAddress,
  labelComplex,
  labelSuburb,
  labelProvince,
  labelCity,
  labelPostalCode,
  labelCheckBox,
  labelCell,
  privacyDisclaimer,
  errorMessage,
  submitButton,
  ariaSubmitButton,
  file,
  setFile,
  formType,
  ibanValidationText,
}) => {
  const { isSending, isError } = formState;
  const {
    firstName,
    lastName,
    email,
    store,
    brand,
    cell,
    region,
    addressLine1,
    addressLine2,
    city,
    addreesLine3,
    stateCode,
    postalCode,
  } = formData;

  const [errorFields, setErrorFields] = useState({});

  const checkboxToBoolean = (value: string): boolean => value === 'on';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value } = e?.target;
    const newValue = type === 'checkbox' ? checkboxToBoolean(value) : value;

    if (name === 'cell' && value.length && ibanValidationText) {
      if (isValid(value) && errorFields.cell) {
        setErrorFields((prevValue) => ({ ...prevValue, cell: false }));
      }
    }

    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: newValue,
    }));

    setFormState((prevVal) => ({ ...prevVal, isError: false }));
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = e.target.files[0];
    setFile(fileUploaded);
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const handleBlur = (e) => {
    const { name, value } = e?.target;
    if (name === 'cell' && value.length && ibanValidationText) {
      if (!isValid(value)) {
        setErrorFields((prevValue) => ({ ...prevValue, cell: true }));
      }
    }
  };

  return (
    <div className="receipt-form__info">
      {description ? (
        <DangerouslySetInnerHtml className="receipt-form__description" html={description} />
      ) : null}

      <form className="receipt-form__form" action="#" onSubmit={handleSubmit}>
        <div className="receipt-form__block-grid">
          <div>
            <Input
              label={labelFirstName}
              value={firstName}
              name="firstName"
              id="receipt-form-firstName"
              type="text"
              required
              onChange={handleChange}
            />
          </div>
          <div>
            <Input
              label={labelLastName}
              onChange={handleChange}
              value={lastName}
              name="lastName"
              id="receipt-form-lastName"
              type="text"
            />
          </div>
          <div>
            <Input
              label={labelEmail}
              value={email}
              name="email"
              id="receipt-form-email"
              type="email"
              required
              onChange={handleChange}
            />
          </div>
          {labelCell && (
            <div>
              <Input
                label={labelCell}
                value={cell}
                name="cell"
                id="receipt-form-cell"
                type="text"
                required
                isError={errorFields.cell}
                validationText={ibanValidationText}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          )}
          {formType !== 'de' && labelShop && (
            <div>
              <NativeDropdown
                options={shopVal}
                label={labelShop}
                value={store}
                name="store"
                id="receipt-form-store"
                required
                onChange={handleChange}
              />
            </div>
          )}
          {formType === 'de' && labelShop && (
            <div>
              <Input
                label={labelShop}
                value={store}
                name="store"
                id="receipt-form-store"
                type="text"
                onChange={handleChange}
              />
            </div>
          )}
          {labelBrand && (
            <div>
              <NativeDropdown
                options={barndVal}
                label={labelBrand}
                value={brand}
                name="brand"
                id="receipt-form-brand"
                required
                onChange={handleChange}
              />
            </div>
          )}
          {labelRegion && (
            <div>
              <NativeDropdown
                options={regionVAl}
                label={labelRegion}
                value={region}
                name="region"
                id="receipt-form-region"
                required
                onChange={handleChange}
              />
            </div>
          )}
        </div>

        {fileTitle ? (
          <DangerouslySetInnerHtml className="receipt-form__block-title" html={fileTitle} />
        ) : null}

        <div className="receipt-form__block-grid">
          <div className="receipt-form__file-wrap">
            <input
              type="file"
              id="upload"
              accept=".png,.jpg,.jpeg,.pdf,.doc,.docx,application/msword"
              className="receipt-form__file-input"
              onChange={handleChangeFile}
              onClick={(event) => {
                event.target.value = null;
              }}
              required
            />
            <label className="receipt-form__file-upload" htmlFor="upload">
              {labelFile}
            </label>
          </div>
          {file && (
            <div className="receipt-form__filename">
              <span>{file.name}</span>
              <Button clickHandler={handleRemoveFile} variant="icon">
                <Icon icon="close" />
              </Button>
            </div>
          )}
        </div>

        {addressTitle ? (
          <DangerouslySetInnerHtml
            element="p"
            className="receipt-form__block-title"
            html={addressTitle}
          />
        ) : null}
        <div className="receipt-form__block-grid">
          {labelAddress && (
            <div>
              <Input
                label={labelAddress}
                value={addressLine1}
                name="addressLine1"
                id="receipt-form-addressLine1"
                type="text"
                required
                onChange={handleChange}
              />
            </div>
          )}
          {labelComplex && (
            <div>
              <Input
                label={labelComplex}
                value={addressLine2}
                name="addressLine2"
                id="receipt-form-addressLine2"
                type="text"
                onChange={handleChange}
              />
            </div>
          )}
          {labelSuburb && (
            <div>
              <Input
                label={labelSuburb}
                value={addreesLine3}
                name="addreesLine3"
                id="receipt-form-addreesLine3"
                type="text"
                required
                onChange={handleChange}
              />
            </div>
          )}
          {labelProvince && (
            <div>
              <Input
                label={labelProvince}
                value={stateCode}
                name="stateCode"
                id="receipt-form-stateCode"
                type="text"
                required
                onChange={handleChange}
              />
            </div>
          )}
          {labelCity && (
            <div>
              <Input
                label={labelCity}
                value={city}
                name="city"
                id="receipt-form-city"
                type="text"
                required
                onChange={handleChange}
              />
            </div>
          )}
          {labelPostalCode && (
            <div>
              <Input
                label={labelPostalCode}
                value={postalCode}
                name="postalCode"
                id="receipt-form-postalCode"
                type="text"
                required
                onChange={handleChange}
              />
            </div>
          )}
        </div>

        <div className="receipt-form__form-group--rest">
          {labelCheckBox && privacyDisclaimer ? (
            <label htmlFor="receipt-form-policy" className="receipt-form__form-checkbox-group">
              <input
                onChange={handleChange}
                type="checkbox"
                name="policy"
                id="receipt-form-policy"
                required
              />
              <span className="checkbox" />
              <DangerouslySetInnerHtml html={privacyDisclaimer} />
            </label>
          ) : null}

          {isError && !!errorMessage && (
            <p className="receipt-form__error" aria-errormessage={errorMessage} live-region="alert">
              <DangerouslySetInnerHtml html={errorMessage} />
            </p>
          )}

          {submitButton ? (
            <Button
              disabled={isSending}
              ariaLabel={ariaSubmitButton}
              type="submit"
              variant="primary"
              isLoading={isSending}
            >
              {submitButton}
            </Button>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default Form;
