import React, { FC, MouseEvent, useState } from 'react';
import Button from 'common/Button';
import IconCustom from 'common/IconCustom';
import { ISlideControls } from '../../models';
import './WCSlideControls.scss';

const WCSlideControls: FC<ISlideControls> = ({
  handlePause,
  handleNextSlide,
  goNextBtn,
  pauseButton,
  goBackBtn,
  handleGoBack,
  autoPlay,
  isOptionPaused,
}) => {
  const [clicked, setClicked] = useState(false);
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleNextSlide();
    setClicked(true);
  };

  return (
    <div className="wc-slide-controls" data-testid="wc-slide-controls">
      <div className="wc-slide-controls__container">
        {goBackBtn?.length && handleGoBack && (
          <Button
            classes="wc-slide-controls__go-back"
            variant="icon"
            iconPrefix="prev"
            clickHandler={handleGoBack}
            ariaLabel={goBackBtn[0]?.properties?.ariaLabel}
          >
            {goBackBtn[0]?.properties?.label}
          </Button>
        )}
        {pauseButton?.length && handlePause && autoPlay && !isOptionPaused && (
          <Button classes="wc-slide-controls__pause" variant="icon" onClick={handlePause}>
            <IconCustom icon="pause" />
            <span className="sr-only">{pauseButton[0]?.properties?.ariaLabel}</span>
          </Button>
        )}
        {goNextBtn?.length && handleNextSlide && (
          <Button
            classes="wc-slide-controls__next"
            variant="icon"
            onClick={handleClick}
            disabled={clicked}
          >
            <IconCustom icon="next" />
            <span className="sr-only">{goNextBtn[0]?.properties?.ariaLabel}</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default WCSlideControls;
